import React, { useState, useEffect } from 'react'
import Group from './Group'
import { FontIcon, TextField } from 'react-md'
import t from 'counterpart'
import './Menu.scss'
import { cloneDeep } from 'lodash'

/**
 * 
 * @param {import('./Menu').MenuProps} param0 
 * @returns {JSX.Element}
 */
const Menu = ({
  baseId = 'mpk-menu',
  className = '',
  collapsible = false,
  defaultCollapse = false,
  defaultPath = null,
  showFilterMenu = true,
  items = [],
  ...props
}) => {
  const [currentItems, setCurrentItems] = useState(items)
  const [search, setSearch] = useState('')
  const [activeMenu, setActiveMenu] = useState(1)

  // useEffect(() => {
  //   if(!mounted) setCurrentItems(items)
  //   setMounted(true)
  // }, [])

  useEffect(() => {
    setCurrentItems(items)
  }, [items, activeMenu])

  const handleSearch = (search) => {
    setSearch(search)
    if (search === '') setCurrentItems(items);
    else {
      let result = [];
      let searchText = `(?=.*${search.replace(/ /g, ')(?=.*')}).*`;
      for (let ig = 0; ig < items.length; ig++) {
        let group = cloneDeep(items[ig]);
        if (!group.children || group.children.length === 0) result.push(group);
        else {
          let children = [];
          for (let ib = 0; ib < group.children.length; ib++) {
            let item = group.children[ib];
            let reference = String(item.index || item.label);
            if (reference.match(new RegExp(searchText, 'ig'))) {
              children.push(item)
            }
          }
          if (children.length > 0) {
            result.push({
              label: group.label,
              children
            })
          }
        }
      }
      setCurrentItems(result)
    }
  }

  const handlePick = (index) => {
    setActiveMenu(index)
    setTimeout(() => setCurrentItems([...items]), 5);
  }

  return (
    <div
      className={`mpk-menu mpk-flex direction-column mpk-full full-height full-width ${className}`}
      {...props}
    >
      <div className="flex-none mpk-full full-width mpk-padding-N padding-top padding-right padding-left">
        <TextField
          id={`mpk-menu-filter-${baseId}`}
          className="mpk-margin-S margin-bottom mpk-full full-width"
          inline
          placeholder={t.translate('mpk.sentence.filterMenu')}
          value={search}
          onChange={e => handleSearch(e.target.value)}
          rightChildren={
            <FontIcon iconClassName="mdi mdi-magnify" />
          }
          style={{ background: 'white' }}
          dense
        />
      </div>
      <div className="flex scrollable mpk-padding-N padding-left padding-right">
        {currentItems.map((d, i) => {
          return <Group
            key={`${baseId}-group-${i}`}
            handlePick={handlePick}
            index={i}
            item={d}
            nextGroup={items[i + 1] ? items[i + 1] : null}
            collapsible
            defaultCollapse={i !== activeMenu}
          />
          // if (i == activeMenu) {
          //   return (
          //     <Group
          //       key={`${baseId}-group-${i}`}
          //       handlePick={handlePick}
          //       index={i}
          //       item={d}
          //       nextGroup={items[i + 1] ? items[i + 1] : null}
          //       {...{
          //         collapsible: true,
          //         defaultCollapse: true
          //       }}
          //     />
          //   )
          // } else {
          //   return (
          //     <Group
          //       key={`${baseId}-group-${i}`}
          //       handlePick={handlePick}
          //       index={i}
          //       item={d}
          //       nextGroup={items[i + 1] ? items[i + 1] : null}
          //       {...{
          //         collapsible: true,
          //         defaultCollapse: false
          //       }}
          //     />
          //   )
          // }
        })}
      </div>
    </div>
  )
}

export default Menu

import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { inject, observer } from 'mobx-react'
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { toast } from "../../libs/react-mpk/services";
import { Logo } from '../../libs/react-mpk/components'
import { Modal } from '../../libs/react-mpk/components'
import renderHTML from 'react-render-html'
import Toc from './../Toc/Toc'
import Privacy from './../Toc/Privacy'
import License from './../Toc/License';

import { getUploadAggrement } from "./Login.service"
import jwt_decode from "jwt-decode";
import fs from "fs";
import path from "path";


import { LoginService } from './Login.service';
import { Dialog, DialogContent, DialogFooter } from 'react-md';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://pajakku.com/">
        Pajakku.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

function SignIn({ authStore, envStore, navigationStore }) {
  const [showPassword, setShowPassword] = React.useState(false);
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [errormsg, setErrormsg] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [agreement, setAgreement] = React.useState(null)
  const [showAgreement, setShowAgreement] = React.useState(false)
  const [visible, setVisible] = React.useState(false)
  const [title, setTitle] = React.useState(false)

  React.useEffect(async () => {
    try {
      if (!agreement) {
        let res = await getUploadAggrement()
        setAgreement(res.data)
      }
    } catch (error) {
      toast.errorRequest(error)
    }
  }, [])

  React.useEffect(() => {
  }, [])
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('masuk')
    try {
      setLoading(true)
      let response = await LoginService({
        username: username,
        password: password
      })
      var decoded = jwt_decode(response.data.token);
      await authStore.setProfile(decoded.bearer);
      await navigationStore.redirectTo('/general/index')
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (error.response && error.response.data && error.response.data.detail && error.response.data.detail !== 'Bad credentials') {
        setErrormsg(error.response.data.detail);
      } else {
        setErrormsg('username atau password salah');
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const modal = (title)=> {
    setVisible(true)
    setTitle(title)
  }
  
  const hide = ()=> {
    setVisible(false)
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Dialog
          id="speed-boost"
          visible={visible}
          onRequestClose={()=> {
            hide()
          }}
          width={800}
          actions={[{
            onClick: ()=> {
              hide()
            },
            primary: true,
            children: 'Tutup',
          }]}
        >
          <DialogContent>
          {title == 'Syarat dan Ketentuan' &&
            <div style={{ cursor: 'pointer' }} dangerouslySetInnerHTML={{ __html: Toc }}></div>
          }
          {title == 'Kebijakan Privasi' &&
            <div style={{ cursor: 'pointer' }} dangerouslySetInnerHTML={{ __html: Privacy }}></div>
          }
          {title == 'Perjanjian Lisensi' &&
            <div style={{ cursor: 'pointer' }} dangerouslySetInnerHTML={{ __html: License }}></div>
          }
          </DialogContent>
          <DialogFooter>
            <Button id="dialog-close" onClick={()=> {
              hide()
            }}>
            Tutup
           </Button>
          </DialogFooter>
        </Dialog>
        <Box
          sx={{
            paddingTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}> */}
          {/* <LockOutlinedIcon /> */}
          {/* </Avatar> */}
          <Box sx={{ m: 2 }}>
            <Logo />
          </Box>
          <Typography component="h1" variant="h5">
            Let's get started!
          </Typography>
          <Box noValidate sx={{ mt: 1 }}>
            <form
              onSubmit={handleSubmit}
              id="auth-form"
              autocomplete={false}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value)
                }}
              />
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Password *</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value)
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              {errormsg &&
                <Typography style={{ color: 'red', textAlign: 'center' }}>
                  {errormsg}
                </Typography>
              }

              <div style={{
                textAlign: 'center',
                fontSize: 13,
                marginTop: 10,
              }}>
                <div className="mpk-margin-N margin-bottom mpk-font-size-NS mpk-font-color-D2">
                  <p>Dengan melakukan sign-in, saya menyatakan telah membaca, mengerti, dan menyetujui
                  <a style={{ cursor: 'pointer', color: 'blue' }}
                    onClick={()=> {
                      modal('Syarat dan Ketentuan')
                    }}
                  > Syarat dan Ketentuan</a>, <a style={{ cursor: 'pointer', color: 'blue' }}
                    onClick={()=> {
                      modal('Kebijakan Privasi')
                    }}
                  >Kebijakan Privasi</a>, dan <a 
                    onClick={()=> {
                      modal('Perjanjian Lisensi')
                    }}
                  style={{ cursor: 'pointer', color: 'blue' }}>Perjanjian Lisensi</a> berlaku.</p>
                </div>
              </div>

              <Button
                style={{
                  backgroundColor: "#c34528"
                }}
                type="submit"
                // onClick={() => handleSubmit()}
                fullWidth
                disabled={loading}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {loading ?
                  <CircularProgress
                    style={{
                      color: 'white',
                      width: 25,
                      height: 25,
                    }}
                  // sx={{ mt: 3, mb: 2 }}
                  />
                  :
                  'Sign In'
                }
              </Button>

            </form>

            <Grid container>
              <Grid item xs>
                <Link onClick={() => navigationStore.redirectTo('/general/forgot')} variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              {/* <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid> */}
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
      <Modal.Info
        baseId='mpk-modal'
        title={'Syarat dan Ketentuan'}
        visible={showAgreement}
        onRequestClose={() => {
          setShowAgreement(false)
        }}
      >
        <div>
          {agreement && renderHTML(agreement)}
        </div>
      </Modal.Info>
    </ThemeProvider>
  );
}

export default inject('authStore', 'envStore', 'navigationStore')(observer(SignIn))
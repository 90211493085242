const Toc = `
<span style='text-align:justify;'>
    <h3><strong><span>Syarat &amp; Ketentuan Umum Layanan Aplikasi Pajakku</span></strong></h3>
    <p><u><span>Mohon di baca dan dipahami syarat dan ketentuan umum Layanan Aplikasi Pajakku dibawah ini :</span></u></p>
    <p><span>Terima kasih telah memilih Layanan Aplikasi PT Mitra Pajakku (selanjutnya disebut &ldquo;<strong>Pajakku</strong>&rdquo;). Pajakku sangat menghargai dan mengapresiasi kepercayaan Pengguna untuk memilih Layanan Aplikasi Pajakku. Maka dari itu Pajakku berusaha selalu memberikan layanan yang terbaik demi kepuasan Pengguna dalam menggunakan layanan Pajakku.</span></p>
    <p><span>Untuk selanjutnya, terdapat Syarat dan Ketentuan Umum Layanan Aplikasi Pajakku (&ldquo;<strong>Syarat dan Ketentuan</strong>&rdquo;) yang mengatur seperangkat peraturan tentang hak dan kewajiban, tanggung jawab<strong>&nbsp;</strong>Pengguna dan Pajakku, serta tata cara Pengguna dalam mengakses dan menggunakan Sistem Layanan Aplikasi Pajakku tentang perpajakan melalui website Pajakku dan Aplikasi seluler Konsul Pajak, termasuk namun tidak terbatas pada layanan yang diakses Pengguna melalui sistem operasi <em>iOS</em> dan Android<em>.</em></span></p>
    <p><span>Syarat dan Ketentuan ini dapat diubah atau diperbarui baik sebagian maupun seluruhnya dari waktu ke waktu tanpa pemberitahuan sebelumnya kepada Pengguna, dan akan berlaku sejak diunggah pada Aplikasi seluler dan/atau Website Pajakku. Pajakku menyarankan agar Pengguna memeriksa Aplikasi seluler dan/atau Website Pajakku untuk mengetahui perubahan apapun atas Syarat dan Ketentuan ini.</span></p>
    <p><span>Pengguna dengan ini menyatakan bahwa Pengguna merupakan Pihak yang telah memenuhi syarat dan mampu mengikatkan dirinya dalam sebuah perjanjian yang sah menurut ketentuan Pasal 1320 Kitab Undang-Undang Hukum Perdata. Syarat dan Ketentuan ini tunduk pada ketentuan peraturan perundang - undangan yang berlaku di Indonesia dan secara otomatis mengikat Pengguna dalam mengunduh, mengunjungi, menggunakan Layanan Aplikasi Pajakku dan/atau fitur dan fasilitas yang disediakan Platform untuk memberikan kepercayaan yang maksimal terhadap Pengguna atas penggunaan Layanan Aplikasi Pajakku sebagaimana dijelaskan pada Pasal 8 pada Syarat dan Ketentuan Umum ini.</span></p>
    <p><span>Kemudian, dengan mendaftar dan/atau menggunakan Produk dan Layanan Aplikasi Pajakku, maka Pengguna dianggap telah membaca, memahami, menyetujui dan akan mematuhi ketentuan yang tercantum di bawah ini. Syarat dan Ketentuan ini juga merupakan suatu kesatuan yang tidak dapat dipisahkan dengan Perjanjian Lisensi, Kebijakan Privasi, Syarat dan Ketentuan, Perjanjian Kerja Sama, dan/atau dokumen lain yang saling mengikat antara Pengguna dan Pajakku.</span></p>

    <div>
        <ol style="margin-left:13px">
            <li style="margin-bottom:5px"><strong><span>Definisi</span></strong></li>
                <div>
                    <p><strong><span>1.1.</span></strong><span>&nbsp;&ldquo;PT Mitra Pajakku (Pajakku)&rdquo; adalah suatu perseroan terbatas yang bergerak dalam bidang Penyedia Jasa Aplikasi Perpajakan (PJAP) yang ditunjuk secara resmi oleh <strong>Direktorat</strong> <strong>Jenderal Pajak (DJP)</strong> sejak tahun 2005 melalui Surat Keputusan nomor SK KEP-20/PJ/2005 hingga perubahan Surat Keputusan terakhir, sebagai berikut:</span></p>
                    <div style="margin-left:13px">
                        <p><strong><span>1.1.1.</span></strong><span>&nbsp;SK KEP-211/PJ/2022 Perpanjangan Penunjukan sebagai Penyedia Jasa Aplikasi Perpajakan;</span></p>
                        <p><strong><span>1.1.2.</span></strong><span>&nbsp;SK KEP-211/PJ/2022 Perpanjangan Penunjukan sebagai Penyalur SPT Elektronik;</span></p>
                        <p><strong><span>1.1.3.</span></strong><span>&nbsp;SK KEP-211/PJ/2022 Perpanjangan Penyedia Aplikasi Pembuatan Kode Biling;</span></p>
                        <p><strong><span>1.1.4.</span></strong><span>&nbsp;SK KEP-211/PJ/2022 Perpanjangan Penyelenggara e-Faktur Host to Host (H2H);</span></p>
                        <p><strong><span>1.1.5.</span></strong><span>&nbsp;SK KEP-211/PJ/2022 Perpanjangan Penyelenggara Aplikasi Pembuatan dan Penyaluran Bukti Pemotongan Elektronik;</span></p>
                        <p><strong><span>1.1.6.</span></strong><span>&nbsp;SK KEP-211/PJ/2022 Perpanjangan Pemberian Nomor Pokok Wajib Pajak (NPWP) untuk Wajib Pajak Orang Pribadi Karyawan;</span></p>
                        <p><strong><span>1.1.7.</span></strong><span>&nbsp;SK KEP-211/PJ/2022 Perpanjangan Penyedia Aplikasi SPT dalam bentuk Dokumen Elektronik;</span></p>
                        <p><strong><span>1.1.8.</span></strong><span>&nbsp;SK KEP-211/PJ/2022 Perpanjangan Pemberian Nomor Pokok Wajib Pajak (NPWP) untuk Wajib Pajak Orang Pribadi Usahawan dan Wajib Pajak Badan;</span></p>
                        <p><strong><span>1.1.9.</span></strong><span>&nbsp;SK KEP-211/PJ/2022 Perpanjangan Penyelenggaraa Layanan Validasi Status Wajib Pajak;</span></p>
                        <p><strong><span>1.1.10.</span></strong><span>&nbsp;SK KEP-211/PJ/2022 Perpanjangan Penyelenggara Layanan API Validasi SPT Tahunan; dan</span></p>
                        <p><strong><span>1.1.11.</span></strong><span>&nbsp;SK KEP-211/PJ/2022 Perpanjangan Penyedia Aplikasi API SPT Pemungut Bea Meterai.</span></p>
                        <p><span>Penunjukan oleh <strong>Direktorat Jenderal Perbendaharaan (DJPb)</strong> sebagai Lembaga Persepsi Lainnya yang melaksanakan Sistem Penerimaan Negara Secara Elektronik melalui Surat Keputusan nomor SK KEP-159/PB/2020, dan penunjukan lain oleh <strong>Perusahaan Umum Percetakan Uang Republik Indonesia (Perum Peruri)</strong> sebagai Distributor Meterai<strong>&nbsp;</strong>Elektronik melalui Surat Penetapan nomor SP-26/I/2024.</span></p>
                    </div>
                    <p><strong><span>1.2.</span></strong><span>&nbsp;</span><span>&ldquo;DJP&rsquo;&rsquo; adalah Direktorat Jendral Pajak yang berada di bawah Kementerian Keuangan Republik Indonesia.</span></p>
                    <p><strong><span>1.3.</span></strong><span>&nbsp;</span><span>&ldquo;DJPB&rsquo;&rsquo; adalah Direktorat Jendral Perbendaharaan yang berada di bawah Kementerian Keuangan Republik Indonesia.</span></p>
                    <p><strong><span>1.4.</span></strong><span>&nbsp;</span><span>&ldquo;Layanan Aplikasi Pajakku&rdquo; adalah segala layanan secara <em>end-to-end</em> dan telah lolos uji dari DJP yang terdiri dari e-Konfirmasi Status Wajib Pajak, Penerimaan Negara Pajakku, e-Pengolahan Pajak Terpadu, Tarra e-Faktur Pajakku, e-Met Pajakku, Sistem Integrasi Perpajakan, Tax Dokumen Center, atau layanan-layanan lain yang Pajakku tawarkan kepada Pengguna dalam bentuk <em>Application as Product</em> ataupun <em>Application as Service</em> (API/Widget) dalam kondisi <em>On-Premise</em> (OP), <em>On-Premise</em> (OP)<em>&nbsp;Hosted</em>, ataupun <em>Software as Service</em> (Saas) dengan layanan yang disebutkan dapat diperbaharui, diubah, dan/atau ditambahkan oleh Pajakku dari waktu ke waktu.</span></p>
                    <p><strong><span>1.5.</span></strong><span>&nbsp;</span><span>&ldquo;<em>Application as Product</em>&rdquo; adalah Layanan Aplikasi Pajakku yang tersedia dalam bentuk tampilan user atau <em>user interface</em> yang dapat secara langsung digunakan oleh Pengguna.</span></p>
                    <p><strong><span>1.6.</span></strong><span>&nbsp;</span><span>&ldquo;<em>Application as Service</em>&rdquo; adalah Layanan Aplikasi Pajakku yang tersedia dalam bentuk <em>Application Programming Interface</em> (API), yang dapat digunakan oleh Pengguna untuk mengintegrasikan l Layanan Aplikasi Pajakku ke Aplikasi lainnya yang dimiliki oleh Pengguna.</span></p>
                    <p><strong><span>1.7.</span></strong><span>&nbsp;</span><span>&ldquo;<em>On-Premise</em> (OP)&rdquo; adalah suatu kondisi saat Aplikasi terinstal pada server yang disediakan oleh Pengguna secara mandiri, server dapat berbentuk cloud maupun server fisik atau mesin.</span></p>
                    <p><strong><span>1.8.</span></strong><span>&nbsp;</span><span>&ldquo;<em>On-Premise</em> (OP) <em>Hosted</em>&rdquo; adalah suatu kondisi saat Aplikasi terinstal pada server yang disediakan oleh Pajakku, server dapat berbentuk cloud maupun server fisik atau mesin, server yang disediakan <em>dedicated</em> digunakan oleh masing-masing Pengguna sesuai yang tercantum dalam kerja sama di antara Pajakku dengan Pengguna.</span></p>
                    <p><strong><span>1.9.</span></strong><span>&nbsp;</span><span>&ldquo;<em>Software as Service</em> (Saas)&rdquo; adalah suatu kondisi Aplikasi pada perangkat server dalam bentuk cloud maupun server fisik, yang dapat digunakan secara bersama oleh seluruh Pengguna Pajakku.</span></p>
                    <p><strong><span>1.10.</span></strong><span>&nbsp;</span><span>&ldquo;Platform&rdquo; adalah sistem pada Layanan Aplikasi Pajakku dan Aplikasi Konsul Pajak yang tersedia dan dapat diakses melalui halaman situs web, halaman situs web seluler, dan aplikasi seluler.</span></p>
                    <p><strong><span>1.11.</span></strong><span>&nbsp;</span><span>&ldquo;Pengguna&rsquo;&rsquo; adalah setiap individu dan/atau badan usaha atau badan hukum yang menggunakan atau mengoperasikan atau mengakses Layanan Aplikasi Pajakku, termasuk namun tidak terbatas pada karyawan atau Pihak yang ditunjuk untuk mewakili badan usaha atau badan hukum untuk menaati ketentuan dalam Perjanjian Lisensi ini, Kebijakan Privasi, Syarat dan Ketentuan, Perjanjian Kerja Sama, dan/atau dokumen lain yang saling mengikat antara Pengguna dan Pajakku.</span></p>
                    <p><strong><span>1.12.</span></strong><span>&nbsp;</span><em><span>&ldquo;Passphrase&rsquo;&rsquo;</span></em><span>&nbsp;adalah serangkaian angka dan/atau huruf dan/atau karakter tertentu yang digunakan sebagai pengaman Sertifikat Elektronik kepada Wajib Pajak yang diterbitkan oleh DJP.</span></p>
                    <p><strong><span>1.13.</span></strong><span>&nbsp;</span><span>&ldquo;<em>Password&rdquo;</em> merupakan angka dan/atau huruf dan/atau karakter tertentu sebagai sandi atau kode yang bersifat rahasia yang digunakan untuk dapat mengakses akun Pengguna atau masuk ke dalam sistem.</span></p>
                    <p><strong><span>1.14.</span></strong><span>&nbsp;</span><em><span>&ldquo;Cookie&rsquo;&rsquo;</span></em><span>&nbsp;adalah bagian kecil teks yang digunakan untuk menyimpan informasi di browser web, menerima identifikasi dan informasi lain di komputer dan perangkat lainnya.</span></p>
                    <p><strong><span>1.15.</span></strong><span>&nbsp;</span><span>&ldquo;Data Pribadi&rdquo; adalah semua data yang diberikan Pengguna yang teridentifikasi atau dapat diidentifikasi secara tersendiri atau dikombinasi dengan informasi lainnya baik secara langsung maupun tidak langsung melalui sistem elektronik atau non elektronik.</span></p>
                </div>
            <br/>
            <li style="margin-bottom:5px"><strong><span>Akun dan Kata Sandi</span></strong></li>
                <div>
                    <p><strong><span>2.1.</span></strong><span>&nbsp;Untuk menggunakan Layanan Aplikasi Pajakku, Pengguna harus terlebih dahulu terdaftar pada Layanan Aplikasi Pajakku dengan melengkapi data-data yang diminta pada saat pendaftaran akun. Pengguna sebagaimana dimaksud dalam Pasal 8.2 Syarat dan Ketentuan ini untuk menjamin informasi yang diberikan adalah informasi yang benar, akurat, lengkap, dan dapat dipertanggungjawabkan secara hukum.</span></p>
                    <p><strong><span>2.2.</span></strong><span>&nbsp;</span><span>Atas informasi Data Pribadi yang disampaikan kepada Pajakku melalui Layanan Aplikasi Pajakku, Pengguna mengerti dan setuju bahwa ketentuan penggunaan Layanan ini merupakan perjanjian antara Pajakku dengan Pengguna dalam bentuk elektronik yang sah dan mengikat secara hukum dan terus berlaku sepanjang Pengguna menjadi Pengguna Layanan Aplikasi Pajakku. Tindakan Pengguna saat daftar menjadi persetujuan aktif dan Pengguna memberikan izin kepada Pajakku untuk memproses Data Pribadi dengan tujuan daftar, hitung, bayar, lapor perpajakan Pengguna.</span></p>
                    <p><strong><span>2.3.</span></strong><span>&nbsp;</span><span>Pajakku secara otomatis akan mengirimkan email konfirmasi pada email terdaftar untuk mengkonfirmasi bahwa akun tersebut benar dibuat oleh Pengguna sebagai proses verifikasi.</span></p>
                    <p><strong><span>2.4.</span></strong><span>&nbsp;</span><span>Pengguna wajib menyetujui dan melakukan verifikasi, serta menjalankan prosedur dan arahan yang ditentukan oleh Pajakku.</span></p>
                    <p><strong><span>2.5.</span></strong><span>&nbsp;</span><span>Pengguna sepenuhnya bertanggungjawab untuk memanfaatkan, mengelola, menyimpan, merubah, menjaga, menghapus kata sandi dan akun, serta atas penggunaan akses yang sah maupun tidak sah yang terjadi di bawah akun Pengguna. Pengguna wajib dengan segera memberitahukan kepada Kami atas setiap dugaan atau aktivitas penggunaan Akun Pengguna pada Layanan Aplikasi Pajakku atau password secara tidak berwenang atau terjadinya pelanggaran keamanan.</span></p>
                </div>
            <br/>
            <li style="margin-bottom:5px"><strong><span>Layanan Aplikasi Pajakku</span></strong></li>
            <div>
                    <p><span>Layanan Aplikasi Pajakku ditawarkan kepada Pengguna dengan cakupan penyediaan berdasarkan kebutuhan Pengguna melalui Platform Pajakku sebagaimana penjelasannya berikut:</span></p>
                    <p><strong><span>3.1.</span></strong><span>&nbsp; e-Konfirmasi Status Wajib Pajak</span></p>
                    <p><span>Aplikasi e-Konfirmasi status Wajib Pajak memfasilitasi penyediaan layanan untuk membuat dan mengkonfirmasi Wajib Pajak atas Registrasi Wajib Pajak, Validasi NPWP, dan Konfirmasi status Wajib Pajak, selengkapnya Pengguna dapat melihat dan mengakses Aplikasi ini melalui tautan&nbsp;</span><a href="https://www.pajakku.com/products"><span style='color:#0563C1;'>https://www.pajakku.com/products</span><span>&nbsp;</span></a><span>(tautan ini dapat diperbaharui oleh Pajakku dari waktu ke</span><u><span style='color:#0563C1;'>&nbsp;</span></u><span>waktu).</span></p>
                    <p><strong><span>3.2.</span></strong><span>&nbsp; Penerimaan Negara Pajakku</span></p>
                    <p><span>Aplikasi Penerimaan Negara Pajakku memfasilitasi penyediaan layanan untuk pembayaran Pajak, Bea Cukai, Penerimaan Negara Bukan Pajak (PNBP) dengan Modul Penerimaan Negara (mpn G3), selengkapnya Pengguna dapat melihat dan mengakses Aplikasi ini melalui tautan&nbsp;</span><a href="https://www.pajakku.com/products"><span style='color:#0563C1;'>https://www.pajakku.com/products</span><span>&nbsp;</span></a><span>(tautan ini dapat diperbaharui oleh Pajakku dari waktu ke</span><u><span style='color:#0563C1;'>&nbsp;</span></u><span>waktu).</span></p>
                    <p><strong><span>3.3.</span></strong><span>&nbsp;</span><span>e-Pengolahan Pajak Terpadu</span></p>
                    <p><span>Aplikasi e-Pengolahan Pajak Terpadu memfasilitasi penyediaan layanan untuk melakukan PPh dan e-Bupot Terpadu mulai dari proses hitung PPh, buat dan bayar kode billing, hingga pelaporan SPT Masa PPh, selengkapnya Pengguna dapat melihat dan mengakses Aplikasi ini melalui tautan&nbsp;</span><a href="https://www.pajakku.com/products"><span style='color:#0563C1;'>https://www.pajakku.com/products</span><span>&nbsp;</span></a><span>(tautan ini dapat diperbaharui oleh Pajakku dari waktu ke</span><u><span style='color:#0563C1;'>&nbsp;</span></u><span>waktu).</span></p>
                    <p><strong><span>3.4.</span></strong><span>&nbsp;</span><span>Tarra e-Faktur Pajakku</span></p>
                    <p><span>Aplikasi Tarra e-Faktur Pajakku memfasilitasi penyediaan layanan untuk melakukan PPN dengan mengolah faktur pajak, nota retur, SPT, dan dokumen tertentu yang kedudukannya dipersamakan dengan faktur pajak, selengkapnya Pengguna dapat melihat dan mengakses Aplikasi ini melalui tautan<span style="color:#0563C1;">&nbsp;</span></span><a href="https://www.pajakku.com/products"><span style='color:#0563C1;'>https://www.pajakku.com/products</span><span style='color:windowtext;'>&nbsp;</span></a><span>(tautan ini dapat diperbaharui oleh Pajakku dari waktu ke waktu).</span></p>
                    <p><strong><span>3.5.</span></strong><span>&nbsp;</span><span>e-Met Pajakku</span></p>
                    <p><span>Aplikasi e-Met Pajakku memfasilitasi penyediaan layanan untuk pembelian meterai elektronik, pembubuhan baik secara single maupun multi-dokumen kepada Pengguna, pelaporan SPT Masa Bea Meterai, dan portal POS e-Meterai, selengkapnya Pengguna dapat melihat dan mengakses Aplikasi ini melalui tautan<span style="color:#0563C1;">&nbsp;</span></span><a href="https://www.pajakku.com/products"><span style='color:#0563C1;'>https://www.pajakku.com/products</span><span style='color:windowtext;'>&nbsp;</span></a><span>(tautan ini dapat diperbaharui oleh Pajakku dari waktu ke waktu).</span></p>
                    <p><strong><span>3.6.</span></strong><span>&nbsp;</span><span>Sistem Integrasi Perpajakan</span></p>
                    <p><span>Aplikasi Sistem Integrasi Perpajakan memfasilitasi penyediaan layanan aplikasi pengolahan pajak untuk pembuatan Faktur Pajak (Ta) dan Bukti Potong (ppt), perekam data transaksi <em>automatic mapping</em> jenis pajak terhutang, menghitung kewajiban perpajakan yang terhutang,<em>&nbsp;</em>melakukan equalisasi rekonsiliasi adjustment, serta pembuatan SPT Tahunan Badan , selengkapnya Pengguna dapat melihat dan mengakses Aplikasi ini melalui tautan&nbsp;</span><a href="https://www.pajakku.com/products"><span style='color:#0563C1;'>https://www.pajakku.com/products</span><span>&nbsp;</span></a><span>(tautan ini dapat diperbaharui oleh Pajakku dari waktu ke</span><u><span style='color:#0563C1;'>&nbsp;</span></u><span>waktu).</span></p>
                    <p><strong><span>3.7.</span></strong><span>&nbsp;</span><span>Tax Dokumen Center</span></p>
                    <p><span>Aplikasi Tax Dokumen Center memfasilitasi penyediaan layanan yang dapat menintegrasikan aplikasi PPh dan PPN, sehingga Aplikasi ini menampilkan dokumen Perpajakan kepada Pengguna dan/atau Pelanggan dan Pemasok Pengguna untuk dapat mengakses data dan mengupload dokumen, selengkapnya Pengguna dapat melihat dan mengakses Aplikasi ini melalui tautan&nbsp;</span><a href="https://www.pajakku.com/products"><span style='color:#0563C1;'>https://www.pajakku.com/products</span><span>&nbsp;</span></a><span>(tautan ini dapat diperbaharui oleh Pajakku dari waktu ke</span><u><span style='color:#0563C1;'>&nbsp;</span></u><span>waktu).</span></p>
            </div>
            <br/>
            <li style="margin-bottom:5px"><strong><span>Persetujuan</span></strong></li>
            <div>
                    <p><strong><span>4.1.</span></strong><span>&nbsp;Pajakku tidak menjual data Pengguna kepada Pihak Eksternal dan tidak membagikan informasi yang dapat mengidentifikasi Pengguna secara langsung.</span></p>
                    <p><strong><span>4.2.</span></strong><span>&nbsp;Pengguna memberikan kewenangan kepada Pajakku untuk menyimpan, mengumpulkan, memproses dan menganalisis Data Pengguna untuk meningkatkan kualitas Layanan Aplikasi Pajakku.</span></p>
                    <p><strong><span>4.3.</span></strong><span>&nbsp;Segala Data yang diserahkan oleh Pengguna kepada Pajakku akan disimpan dan dijaga keamanannya dalam masa retensi Perjanjian maupun portal Aplikasi Pajakku.</span></p>
                    <p><strong><span>4.4.</span></strong><span>&nbsp;Terhadap masa retensi sebagaimana dimaksud dalam Pasal 4.3, masa retensi ditentukan dari Layanan yang masih dalam periode berlangganan oleh Pengguna, sebagai berikut:</span></p>
                    <p><strong><span>4.4.1</span></strong><span>&nbsp;</span><span>Kondisi instalasi <em>On-Premise (OP)</em>, masa retensi Data maksimum 10 (sepuluh) tahun sejak tanggal penyerahan Data oleh Pengguna;</span></p>
                    <p><strong><span>4.4.2</span></strong><span>&nbsp;</span><span>Kondisi instalasi <em>On-Premise (OP) Hosted</em>, masa retensi Data maksimum 3 (tiga) tahun terhitung sejak tanggal penyerahan Data oleh Pengguna; dan</span></p>
                    <p><strong><span>4.4.3</span></strong><span>&nbsp;</span><span>Kondisi Aplikasi <em>Software as Service (Saas)</em>, masa retensi Data maksimum 3 (tiga) tahun terhitung sejak tanggal penyerahan Data oleh Pengguna.</span></p>
                    <p><strong><span>4.5.</span></strong><span>&nbsp;</span><span>Permohonan <em>back up</em> data, migrasi server, penambahan server, perubahan koneksi jaringan server, atau kegiatan simulasi yang berkaitan dengan Aplikasi terhadap kondisi instalasi pada Pasal 4.4.1 akan dikenakan biaya <em>person days</em> sesuai kebijakan yang ditetapkan Pajakku.</span></p>
                    <p><strong><span>4.6.</span></strong><span>&nbsp;</span><span>Permintaan penambahan masa retensi dengan kondisi sebagaimana dimaksud pada Pasal 4.4 hanya dapat dilakukan pada Pasal 4.4.2 dengan penambahan sampai dengan maksimum 10 (sepuluh) tahun sejak tanggal penyerahan Data oleh Pengguna melalui pengisian Formulir Perpanjangan Masa Retensi atau Pengguna dapat menghubungi <u>marketing@pajakku.com</u>.</span></p>
                    <p><strong><span>4.7.</span></strong><span>&nbsp;</span><span>Apabila Pengguna dengan kondisi sebagaimana dimaksud pada Pasal 4.4.2 dan Pasal 4.4.3 melakukan pemutusan masa berlangganan sebelum periode masa retensi Data yang tercantum pada Pasal 4.4.2 dan Pasal 4.4.3, maka Data Pengguna akan secara otomatis dihapus dari <em>database</em> Pajakku.</span></p>
                    <p><strong><span>4.8.</span></strong><span>&nbsp;</span><span>Pajakku hanya akan memberikan data Pengguna kepada pihak terkait atau pihak yang diberi wewenang oleh Pajakku. Penyebarluasan data hanya akan dilakukan kepada pihak yang diizinkan oleh Pengguna.</span></p>
                    <p><strong><span>4.9.</span></strong><span>&nbsp;</span><span>Pengguna memberikan izin kepada Pajakku untuk memanfaatkan <em>cookie</em> dan melakukan pengiriman pesan pemasaran elektronik, dan update yang perlu diketahui oleh Pengguna melalui alamat email dan/atau melalui nomor telepon terdaftar.</span></p>
                    <p><strong><span>4.10.</span></strong><span>&nbsp;</span><span>Pengguna memahami dan menyetujui bahwa menggunakan Layanan Aplikasi Pajakku dengan risiko Pengguna sendiri.</span></p>
                    <p><strong><span>4.11.</span></strong><span>&nbsp;</span><span>Pajakku berhak kapan pun mengungkapkan informasi jika dianggap perlu untuk mematuhi undang-undang, peraturan, proses hukum, atau permintaan Lembaga Negara yang berlaku, secara keseluruhan maupun sebagian, berdasarkan kebijaksanaan Pajakku.</span></p>
                    <p><strong><span>4.12.</span></strong><span>&nbsp;</span><span>Pajakku dengan upaya terbaik menyediakan layanan untuk Pengguna, namun tidak memberikan jaminan bahwa penggunaan terhadap Layanan Aplikasi Pajakku akan selalu sesuai dengan harapan Pengguna, selalu ada dalam kualitas terbaik atau terbebas dari virus, kerus akan, bug, error, kehilangan data, dan gangguan lainnya dan Pengguna melepaskan Pajakku dari tuntutan sehubungan dengan terjadinya hal-hal tersebut.</span></p>
                    <p><strong><span>4.13.</span></strong><span>&nbsp;</span><span>Berhubungan dengan ketentuan dari Pasal 4.12, Pajakku akan tetap memberikan bantuan serta pelayanan terbaik untuk setiap kendala yang mungkin timbul sehingga dapat segera terselesaikan dengan baik.</span></p>
                    <p><strong><span>4.14.</span></strong><span>&nbsp;</span><span>Pengguna menyewa Aplikasi yang disediakan oleh Pajakku. Pengguna menyatakan dan menjamin bahwa Aplikasi ini adalah kepunyaan dan milik Pajakku, termasuk namun tidak terbatas pada Pengembangan, Pembaharuan dan/atau Rilis Baru, Perangkat Lunak, dan apabila diperlukan adanya koneksi antara perangkat keras teknologi informasi dan peralatan lain milik Penyedia Jasa ke perangkat teknologi informasi, sistem, jaringan, atau infrastruktur.</span></p>
                    <p><strong><span>4.15.</span></strong><span>&nbsp;</span><span>Pengguna wajib memenuhi pelaksanaan Pencabutan Aplikasi yang telah disewa Pengguna dari Pajakku dengan terinstal dalam kondisi <em>On-Premise</em> (OP) atau <em>On-Premise</em> (OP) <em>Hosted</em> setelah berakhirnya masa keanggotaan atau membership Pengguna sebagaimana akan dituangkan dalam Berita Acara Serah Terima (BAST) Pencabutan yang wajib ditandatangani oleh Pengguna dan Pajakku sebagai bentuk persetujuan dan jaminan dari penyerahkan kembali Aplikasi.</span></p>
                    <p><strong><span>4.16.</span></strong><span>&nbsp;</span><span>Pengguna menyatakan dan menjamin tidak melanggar, mengalihkan, memindahkan atau menyerahkan sebagian atau seluruh hak kekayaan intelektual dalam bentuk apapun milik Pajakku seperti hak paten, merek dagang, cipta, indikasi geografis, rahasia dagang atau penemuan-penemuan, hak atas sumber data, nama domain, alat dan/atau tanda (yang sudah dan/atau masih dalam proses pendaftaran) serta semua hak kekayaan intelektual lainnya untuk pelaksanaan atas hak tersebut yang dimiliki pada saat ini atau di masa yang akan datang.</span></p>
                    <p><strong><span>4.17.</span></strong><span>&nbsp;</span><span>Pengguna menyatakan dan menjamin untuk membebaskan Direktorat Jenderal Pajak (DJP), Direktorat Jenderal Perbendaharaan (DJPb), Perusahaan Umum Percetakan Uang Republik Indonesia (PERURI), dan Dinas Kependudukan dan Pencatatan Sipil (Dukcapil) dari segala tuntutan yang terkait dengan penyediaan Layanan Aplikasi Pajakku.</span></p>
            </div>
            <br/>
            <li style="margin-bottom:5px"><strong><span>Larangan</span></strong></li>
            <div>
                    <p><span>Selama menggunakan Layanan Aplikasi Pajakku, Pengguna dilarang secara sengaja maupun tidak sengaja, untuk :</span></p>
                    <p><strong><span>5.1.</span></strong><span>&nbsp; Mengakses atau mencoba mendapatkan akses selain dari hak atau wewenang Pengguna;</span></p>
                    <p><strong><span>5.2.</span></strong><span>&nbsp;Menyalahgunakan, mengubah, menyalin, meniru, membongkar, pemasangan BOT atau melakukan <em>reverse engineering</em> atas Layanan Aplikasi Pajakku yang digunakan;</span></p>
                    <p><strong><span>5.3.</span></strong><span>&nbsp;Meneliti, memindai, atau menguji kerentanan Layanan Aplikasi pajakku atau sistem maupun jaringan terkait, atau mengganggu langkah pengamanan atau pemeriksaan autentikasi yang digunakan terkait Layanan Aplikasi Pajakku atau sistem dan jaringannya;</span></p>
                    <p><strong><span>5.4.</span></strong><span>&nbsp;Melakukan tindakan yang merugikan atau menimbulkan ancaman terhadap Pajakku, termasuk menyebarkan virus, menimbulkan kelebihan beban atau mengirim spam;</span></p>
                    <p><strong><span>5.5.</span></strong><span>&nbsp;Memalsukan identitas pribadi, perusahaan, atau afiliasi Pengguna sebagai orang lain atau entitas hukum lain;</span></p>
                    <p><strong><span>5.6.</span></strong><span>&nbsp;Menggunakan Layanan Aplikasi Pajakku untuk meraup keuntungan finansial &amp; non finansial dari diri sendiri atau orang lain lain, tanpa persetujuan Pajakku;</span></p>
                    <p><strong><span>5.7.</span></strong><span>&nbsp;Pajakku berhak untuk membatasi atau meniadakan sama sekali akses Pengguna untuk menggunakan Layanan Aplikasi Pajakku lebih lanjut jika penggunaan menimbulkan pelanggaran terhadap hukum yang berlaku;</span></p>
                    <p><strong><span>5.8.</span></strong><span>&nbsp;Pajakku berdasarkan kebijakan sendiri, berhak untuk menangguhkan atau menghentikan akses atau &nbsp;penggunaan &nbsp; terhadap &nbsp;Layanan &nbsp;Aplikasi &nbsp; Pajakku &nbsp;tanpa &nbsp;pemberitahuan &nbsp; terlebih &nbsp;dahulu kepada &nbsp;Pengguna &nbsp; apabila &nbsp;Pengguna &nbsp;melakukan &nbsp; pelanggaran &nbsp;terhadap &nbsp;Perjanjian &nbsp; Lisensi,</span></p>
                    <p><span>Kebijakan Privasi, Syarat dan Ketentuan, Perjanjian Kerja Sama, dan/atau dokumen lain yang saling mengikat antara Pengguna dan Pajakku;</span></p>
                    <p><strong><span>5.9.</span></strong><span>&nbsp;</span><span>Pajakku tanpa pemberitahuan terlebih dahulu kepada Pengguna, memiliki kewenangan untuk melakukan tindakan yang perlu atas setiap dugaan pelanggaran ketentuan hukum yang berlaku di Indonesia; dan</span></p>
                    <p><strong><span>5.10.</span></strong><span>&nbsp;</span><span>Setiap pelanggaran yang dilakukan memiliki konsekuensi hukum dan diproses sesuai hukum yang berlaku di Indonesia.</span></p>
            </div>
            <br/>
            <li style="margin-bottom:5px"><strong><span>Batasan Tanggung Jawab Pajakku</span></strong></li>
            <div>
                    <p><strong><span>6.1.</span></strong>&nbsp;<span>Pajakku tidak bertanggung jawab terhadap kesalahan <em>(human error)</em> dan atas segala dampak kerugian yang timbul akibat tindakan pengguna dalam menggunakan Layanan Aplikasi Pajakku.</span></p>
                    <p><strong><span>6.2.</span></strong>&nbsp;<span>Pajakku tidak bertanggung jawab atas kesalahan yang terjadi, yang termasuk namun tidak terbatas pada:</span></p>
                    <p><strong><span>6.2.1.</span></strong><span>&nbsp;Kesalahan dan/atau ketidak akuratan data dalam sistem yang di masukkan Pengguna;</span></p>
                    <p><strong><span>6.2.2.</span></strong><span>&nbsp;Perubahan kebijakan akibat peraturan baru dari seluruh otoritas yang berwenang; dan/atau</span></p>
                    <p><strong><span>6.2.3.</span></strong><span>&nbsp;Gangguan server atau koneksi internet Pengguna saat menggunakan Layanan Aplikasi Pajakku.</span></p>
                    <p><strong><span>6.3.</span></strong><span>&nbsp;</span><span>Pajakku telah memenuhi standarisasi:</span></p>
                    <p><strong><span>6.3.1.</span></strong><span>&nbsp;ISO 27001:2022 tentang <em>Information Security Management;</em></span></p>
                    <p><strong><span>6.3.2.</span></strong><span>&nbsp;ISO 9001:2015 tentang <em>Quality Management Systems;</em></span></p>
                    <p><strong><span>6.3.3.</span></strong><span>&nbsp;ISO 20000-1 :2018 tentang <em>Service Management System Requirements;</em></span></p>
                    <p><strong><span>6.3.4.</span></strong><span>&nbsp;<em>Penetration Test</em> (Pentest) yang dilakukan oleh Pihak Ketiga;</span></p>
                    <p><strong><span>6.3.5.</span></strong><span>&nbsp;<em>User Acceptance Testing</em> (UAT) dari DJP; dan</span></p>
                    <p><strong><span>6.3.6.</span></strong><span>&nbsp;<em>User Acceptance Testing</em> (UAT) &amp; <em>System Integration Testing</em> (SIT) dari DJPb.</span></p>           
            </div>
            <br/>
            <li style="margin-bottom:5px"><strong><span>Domisili Hukum dan Penyelesaian Perselisihan</span></strong></li>
            <div>
                    <p><strong><span>7.1.</span></strong>&nbsp;<span>Syarat dan Ketentuan ini dilaksanakan menurut ketentuan hukum yang berlaku di Negara Kesatuan Republik Indonesia, tanpa memperhatikan pertentangan aturan hukum.</span></p>
                    <p><strong><span>7.2.</span></strong>&nbsp;<span>Pengguna setuju bahwa tindakan hukum apapun atau sengketa yang mungkin timbul dari, berhubungan dengan, atau berada dalam cara apapun berhubungan dengan Pajakku dan/atau Syarat & Ketentuan ini akan diselesaikan secara eksklusif dalam yurisdiksi pengadilan Republik Indonesia.</span></p>         
            </div>
            <br/>
            <li style="margin-bottom:5px"><strong><span>Kebijakan Data Privasi</span></strong></li>
            <div>
                    <p><strong><span>8.1.</span>&nbsp;</strong><span>Dalam menggunakan Platform Pajakku, Pengguna akan memberikan informasi mengenai Data Pribadi Pengguna untuk diolah, dikumpulkan, disimpan, dan digunakan oleh Pajakku untuk pemberian Layanan Aplikasi Pajakku dan tujuan lain sebagaimana tercantum dalam ketentuan&nbsp;</span><a href="https://www.pajakku.com/legal/PP/Kebijakan-Privasi"><span style='color:#0563C1;'>Kebijakan Privasi</span><span>&nbsp;</span></a><span>(&ldquo;<strong>Kebijakan Privasi</strong>&rdquo;).</span></p>
                    <p><span>Pajakku akan mengumpulkan Data Pribadi Pengguna namun tidak terbatas untuk Data individu meliputi nama lengkap, nomor KTP atau Paspor atau Kartu Identitas lainnya yang berlaku, nomor NPWP atau NITKU, alamat, alamat e-mail, nomor handphone atau telepon. Sedangkan untuk Data badan usaha atau badan hukum meliputi nama badan usaha atau badan hukum, nomor NPWP atau NITKU badan usaha atau badan hukum atau nomor NIK Konsultan Pajak (apabila Pengguna termasuk Konsultan Pajak, maka perlu memberikan informasi Jenis Sertifikat Konsultan Pajak, nomor KIP Konsultan Pajak, nomor Izin Kuasa Hukum Konsultan Pajak, Asosiasi Konsultan Pajak), alamat badan usaha atau badan hukum, alamat e-mail resmi badan usaha atau badan hukum, nomor telepon Perwakilan badan usaha atau badan hukum, dan data lainnya yang jika diperlukan untuk diberikan kepada Pajakku atas kebutuhan Pengguna.</span></p>
                    <p><strong><span>8.2.</span></strong><span>&nbsp;</span><span>Perubahan, penambahan, atau pembaharuan Informasi Elektronik dapat dilakukan dengan Penggunamengakses melalui akun Pengguna termasuk namun tidak terbatas pada pengkinian Data Pribadi Pengguna, guna menjaga agar Informasi Elektronik yang disampaikan benar, akurat, dan lengkap dari waktu ke waktu.</span></p>
                    <p><strong><span>8.3.</span></strong><span>&nbsp;</span><span>Pajakku hanya menggunakan Data Pribadi Pengguna untuk kebutuhan penggunaan Layanan Aplikasi Pajakku dan Pajakku tidak akan memberikan Data Pribadi Pengguna kepada Pihak Ketiga tanpa persetujuan dari Pengguna. Namun, apabila atas persetujuan dari Pengguna untuk memberikan Data Pribadi kepada Pihak Ketiga, maka Pajakku tidak bertanggung jawab atas segala akibat atau kerugian yang timbul dari penyerahan Data Pribadi oleh Pihak Ketiga. Pihak Ketiga dengan ini menyetujui dan tunduk pada setiap ketentuan dalam Perjanjian Lisensi, Kebijakan Privasi, Syarat dan Ketentuan, Perjanjian Kerja Sama, dan/atau dokumen lain yang saling mengikat antara Pengguna dan Pajakku.</span></p>
                    <p><strong><span>8.4.</span></strong><span>&nbsp;</span><span>Pajakku akan menyimpan dan menjaga Data Pribadi Pengguna dengan langkah yang wajar menggunakan sistem elektronik secara andal, aman, dan bertanggung jawab sesuai dengan prinsip Perlindungan Data Pribadi.</span></p>
                    <p><strong><span>8.5.</span></strong><span>&nbsp;</span><span>Pajakku akan memastikan Data Pribadi Pengguna terhindar dari perusakan, perubahan, pemblokiran, pengungkapan atau akses yang tidak sah, penyalinan, penyebaran atau jenis pemrosesan yang tidak sah lainnya. Pajakku berhak dalam melakukan pemutusan hak akses Pengguna ke Akun Layanan Aplikasi Pajakku dan mengakhiri sewaktu-waktu dalam hal terjadi pelanggaran atas Syarat dan Ketentuan ini. Pengguna dengan ini menyetujui atas risiko yang terjadi bahwa data dan informasi yang tercatat di Akun Pajakku tidak dapat diakses atau diperoleh lagi oleh Pengguna setelah terjadinya pemutusan atau pengakhiran tersebut.</span></p>
                    <p><strong><span>8.6.</span></strong><span>&nbsp;</span><span>Apabila Penggunamemberikan pernyataan dan jaminan, informasi atau Data Pribadi yang tidak benar, tidak jelas, tidak akurat, dan/atau tidak lengkap, maka Pajakku berhak menolak permohonan Pengguna dan tidak memberikan akses dalam Layanan Aplikasi Pajakku kepada Pengguna.</span></p>
                    <p><strong><span>8.7.</span></strong><span>&nbsp;</span><span>Pajakku akan melakukan usaha terbaiknya dalam melakukan pengamanan dan penyimpanan Data Pribadi Pengguna dari pemrosesan yang tidak sah, selama Pengguna masih menggunakan Layanan Aplikasi Pajakku dan mematuhi ketentuan peraturan perundang-undangan. Hal ini dimaksud untuk menjadi dasar landasan atas gugatan hukum yang mungkin timbul, terbatas pada jangka waktu yang diperlukan dan masa retensi sejak tanggal Pengguna berhenti menggunakan Layanan Aplikasi Pajakku.</span></p>
                    <p><strong><span>8.8.</span></strong><span>&nbsp;</span><span>Pajakku akan menyampaikan pemberitahuan kepada Pengguna apabila terjadi kegagalan Pelindungan Data Pribadi Pengguna sesuai dengan ketentuan pada peraturan perundang-undangan yang berlaku.</span></p>
                    <p><strong><span>8.9.</span></strong><span>&nbsp;</span><span>Pajakku dapat melakukan penghapusan Data Pribadi dengan syarat Pengguna menutup dan mengakhiri penggunaan akun pada Layanan Aplikasi Pajakku dan permintaan Pengguna yang disebabkan penetapan pengadilan. Atas Data Pribadi yang telah dihapus, Pengguna tidak dapat melihat Kembali dan Pajakku tidak dapat menampilkannya seperti sebelumnya.</span></p>          
            </div>
            <br/>
            <li style="margin-bottom:5px"><strong><span>Peraturan Terkait</span></strong></li>
            <div>
                <p><span>Syarat dan Ketentuan ini merupakan salah satu kewajiban Pajakku untuk memenuhi Peraturan - Peraturan antara lain :</span></p>
                    <p><strong><span>9.1.</span></strong>&nbsp;<span>Undang-Undang Nomor 8 Tahun 1999 tentang Perlindungan Konsumen;</span></p>
                    <p><strong><span>9.2.</span></strong>&nbsp;<span>Undang-Undang Nomor 11 Tahun 2008 tentang Informasi dan Transaksi Elektronik;</span></p>
                    <p><strong><span>9.3.</span></strong>&nbsp;<span>Undang-Undang Nomor 19 Tahun 2016 tentang Perubahan Atas Undang -Undang Nomor 11 Tahun 2008 tentang Informasi dan Transaksi Elektronik;</span></p>
                    <p><strong><span>9.4.</span></strong>&nbsp;<span>Undang-Undang Nomor 27 Tahun 2022 tentang Pelindungan Data Pribadi;</span></p>
                    <p><strong><span>9.5.</span></strong>&nbsp;<span>Peraturan Pemerintah Nomor 71 Tahun 2019 tentang Penyelenggaraan Sistem dan Transaksi Elektronik;</span></p>
                    <p><strong><span>9.6.</span></strong>&nbsp;<span>Peraturan Pemerintah Nomor 80 Tahun 2019 tentang Perdagangan Melalui Sistem Elektronik;</span></p>
                    <p><strong><span>9.7.</span></strong>&nbsp;<span>Peraturan Pemerintah Nomor 86 Tahun 2021 tentang Pengadaan, Pengelolaan, dan Penjualan Meterai;</span></p>
                    <p><strong><span>9.8.</span></strong>&nbsp;<span>Peraturan Menteri Keuangan Nomor 134/PMK.03/2021 tentang Pembayaran Bea Meterai, Ciri Umum dan Ciri Khusus pada Meterai Tempel, Kode Unik dan Keterangan Tertentu pada Meterai Elektronik, Meterai dalam Bentuk Lain, dan Penentuan Keabsahan Meterai, serta Pemeteraian Kemudian;</span></p>
                    <p><strong><span>9.9.</span></strong>&nbsp;<span>Peraturan &nbsp;Menteri &nbsp; Keuangan &nbsp;Nomor &nbsp;32/PMK.05/2014 &nbsp;tentang &nbsp; Sistem &nbsp;Penerimaan &nbsp;Negara Secara Elektronik, sebagaimana telah beberapa kali diubah terakhir dengan Peraturan Menteri</span></p>
                    <p><span>Keuangan Nomor 202/PMK.05/2018 tentang Perubahan Kedua Atas Peraturan Menteri Keuangan Nomor 32/PMK.05/2014;</span></p>
                    <p><strong><span>9.10.</span></strong><span>&nbsp;</span><span>Peraturan Direkrut Jenderal Pajak Nomor PER-26/PJ/2014 tentang Sistem Pembayaran Pajak Secara Elektronik;</span></p>
                    <p><strong><span>9.11.</span></strong><span>&nbsp;</span><span>Peraturan Direktur Jenderal Pajak Nomor PER-11/PJ/2019 tentang Penyedia Jasa Aplikasi Perpajakan;</span></p>
                    <p><strong><span>9.12.</span></strong><span>&nbsp;</span><span>Peraturan Direktur Jenderal Pajak Nomor PER-10/PJ/2020 tentang Perubahan Atas Peraturan Direktur Jenderal Pajak Nomor PER-11/PJ/2019 tentang Penyedia Jasa Aplikasi Perpajakan;</span></p>
                    <p><strong><span>9.13.</span></strong><span>&nbsp;</span><span>Peraturan Direktur Jenderal Perbendaharaan Nomor PER-2/PB/2023 tentang Perubahan Atas Peraturan Direktur Jenderal Perbendaharaan Nomor PER -8/PB/2021 tentang Petunjuk Teknis Penetapan Maksimum Pencairan Penerimaan Negara Bukan Pajak Secara Elektronik;</span></p>
                    <p><strong><span>9.14.</span></strong><span>&nbsp;</span><span>Peraturan Menteri Komunikasi dan Informatika Nomor 4 tahun 2016 tentang Sistem Manajemen Pengamanan Informasi;</span></p>
                    <p><strong><span>9.15.</span></strong><span>&nbsp;</span><span>Peraturan Menteri Komunikasi dan Informatika Nomor 20 tahun 2016 tentang Perlindungan Data Pribadi dalam Sistem Elektronik;</span></p>
                    <p><strong><span>9.16.</span></strong><span>&nbsp;</span><span>Peraturan Menteri Komunikasi dan Informatika Nomor 5 tahun 2020 tentang Penyelenggara Sistem Elektronik Lingkup Privat;</span></p>
                    <p><strong><span>9.17.</span></strong><span>&nbsp;</span><span>Peraturan Badan Siber dan Sandi Negara Nomor 8 tahun 2020 tentang Sistem Pengamanan dalam Penyelenggaraan Sistem Elektronik; dan</span></p>
                    <p><strong><span>9.18.</span></strong><span>&nbsp;</span><em><span>The European Union (EU) General Data Protection Regulation (GDPR).</span></em></p>
            </div>
        </ol>
    </div>
    <br/>
    <p>Pajakku memiliki hak untuk melakukan perubahan dari waktu ke waktu atas Syarat dan Ketentuan ini. Atas segala perubahan, penghapusan, modifikasi, dan lainnya yang dilakukan berdasarkan pada perubahan peraturan perundang-undangan, peraturan lembaga terkait yang berwenang ataupun perubahan praktik pengolahan data yang dilakukan oleh Pajakku. Oleh karenanya, Pengguna diharapkan selalu membaca Syarat dan Ketentuan ini melalui situs web&nbsp;</span><a href="http://www.pajakku.com/"><span>www.pajakku.com.</span></a></p>
    <br/>
    <p>Dengan ini Pengguna menyatakan telah membaca, mengerti dan setuju untuk terikat pada Syarat dan Ketentuan ini serta perubahannya.</p>
    <br/>
    <p>Saya (Pengguna) Setuju</p>
    <br/>
    <p><strong><span>Kontak</span></strong></p>
    <p><span>Apabila Pengguna memiliki keberatan atau pertanyaan lebih lanjut berkaitan dengan Syarat dan Ketentuan ini maka Pengguna dapat menghubungi Pajakku melalui&nbsp;</span><a href="mailto:marketing@pajakku.com"><span>marketing@pajakku.com.</span></a></p>
    <br/>
    <p>Diperbaharui pada tanggal : 22 Juli 2024</p>
    
</span>
`

export default Toc
import React, { useState, useEffect, Children } from "react";
import { DataForm } from "../../../libs/react-mpk/components";
import { TableWrapper } from "../../../libs/react-mpk/wrapper"
import { toast } from "../../../libs/react-mpk/services"
import { inject, observer } from "mobx-react";
import service, { addDataNpwp } from "./TabelNpwp.service";
import { getDataRole } from "../TabelRoles/TabelRole.service";
import { find } from "lodash";
import { options } from "superagent";

const { inputTypes } = DataForm;
const AddUser = ({ visible = false, onRequestClose = null, item, ...props }) => {
    const [ready, setReady] = useState(false)
    const [roleList, setRoleList] = useState([])
    useEffect(async () => {
        const dataRole = await getDataRole({ page: 0, size: 50 })
        setRoleList(dataRole.data)
        if (visible)
            setReady(true)
    }, [visible])
    return ready && (
        <DataForm
            baseId="add-npwp"
            title="Tambah NPWP"
            asDialog={true}
            visible={visible}
            onRequestClose={() => {
                setReady(false)
                onRequestClose()
            }}
            defaultData={{
                file: null
            }
            }
            definitions={[
                {
                    inputType: inputTypes.FILE_INPUT,
                    label: "AKTIVASI NPWP",
                    key: "file",
                    accept: '.csv',
                    required: true,
                    multiple: true,
                    multiline: true,
                },
            ]}
            onSubmit={async (data, callback) => {
                for (const item of data.file) {
                    try {
                        let response = await addDataNpwp(item.file)
                        // TableWrapper.updateDataItem("mod-table-npwp", { id: response.data.id }, response.data)
                        TableWrapper.reload("mod-table-npwp")
                        callback("Data Berhasil Ditambah", false)
                    } catch (error) {
                        if (error.response && error.response.data && error.response.data.detail) {
                            callback(error.response.data.detail, true, false)
                        } else {
                            callback(error, true, false)
                        }
                    }
                }
            }}
        />
    );
};

export default inject("envStore")(observer(AddUser));

import React, { useEffect, useState } from "react";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import { inject, observer } from "mobx-react";
import { DataForm } from "../../../libs/react-mpk/components";
import {
  getIsSaveFile,
  postIsSaveFile,
  putIsSaveFile,
} from "./IsSaveSetting.service";
// import { inputTypes } from "../../../libs/react-mpk/config/constant";
// import t from "counterpart";
// import { find } from "lodash";

const IsSaveSetting = () => {
  const { inputTypes } = FormWrapper;
  const [IsSaveFilData, setIsSaveFilData] = useState(null);

  const fetchIsSaveFil = async () => {
    try {
      const res = await getIsSaveFile();
      if (res?.data) setIsSaveFilData(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchIsSaveFil();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-starat",
      }}
    >
      <div style={{ margin: "2em 0" }}>
        <DataForm
          baseId="mod-form-sample"
          // title={t.translate("modules.form.title")}
          hintMessage="Setting Scan Bukti Potong"
          // hintShowIcon={false}
          submitLabel="Simpan"
          defaultData={{
            isSaveFile: IsSaveFilData?.isSaveFile || "",
          }}
          definitions={[
            {
              inputType: inputTypes.CHECKBOX,
              label: "Is Save File",
              key: "isSaveFile",
            },
          ]}
          onChange={(data, key, value) => {
            return data;
          }}
          onSubmit={async (data, callback) => {
            try {
              // if (!IsSaveFilData) await postIsSaveFil(data);
              // if (IsSaveFilData)
              await putIsSaveFile(data);

              callback("Berhasil Update Pengaturan Scan Bukti Potong", false);
              console.log(data);
            } catch (error) {
              if (error.response && error.response.data && error.response.data.detail) {
                callback(error.response.data.detail, true)
              } else {
                callback(error, true)
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default inject("envStore")(observer(IsSaveSetting));

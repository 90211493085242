import React, { useState, useEffect } from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service, { getDataSftp, deleteDataSftp } from './TabelSftp.service'
import moment from 'moment'
import { inject, observer } from "mobx-react";
import AddSftp from "./AddSftp";
import { toast } from '../../../libs/react-mpk/services'

const TableSftp = ({
  className = '',
  showCommandbar = true,
  title,
  ...props
}) => {
  let [showForm, setShowForm] = useState(false);
  let [selectedItem, setSelectedItem] = useState(null)
  let [sftplist, setSftpList] = useState([])

  useEffect(async () => {
    try {
      const dataList = await getDataSftp({ page: 0, size: 50 })
      if (dataList) {
        setSftpList(dataList.data)
      }
    } catch (error) {
      console.log("🚀 ~ file: TabelSftp.js ~ line 27 ~ useEffect ~ error", error)
    }
  }, [])

  return (
    <>
      <TableWrapper
        baseId="mod-table-sftp"
        title="Sftp List"
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        useFilter={false}
        defaultSortBy="createdDate"
        useCriteria={true}
        columns={[
          {
            label: 'Host',
            sortable: true,
            searchable: true,
            render: item => item.host
          },
          {
            label: 'Port',
            sortable: true,
            searchable: true,
            render: item => item.port

          },
          {
            label: 'Username',
            sortable: true,
            searchable: true,
            render: item => item.username

          },
          {
            label: 'Password',
            sortable: true,
            searchable: true,
            render: item => item.password
          },
          {
            label: 'File Path',
            sortable: true,
            searchable: true,
            render: item => item.pathFile
          },
          {
            label: 'Created By',
            searchable: true,
            sortable: true,
            key: 'createdBy',
            render: item => (
              <div>{item.createdBy}</div>
            )
          }
        ]}
        actions={[
          new TableWrapper.action('Add', 'mdi mdi-plus', () => {
            if (sftplist.lenght > 0) {
              toast.warning("Data Setting Sftp hanya bisa di input 1 saja!!!")
            } else {
              setSelectedItem(null)
              setShowForm(true)
            }

          }, true)
        ]}
        itemActions={[
          new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => {
            setShowForm(true)
            setSelectedItem(item)
          }, true),
          new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => {
            props.modalStore.showConfirm({
              title: "Delete",
              children: "yakin?",
              onSubmit: async (callback) => {
                try {
                  await deleteDataSftp(item.id)
                  TableWrapper.reload("mod-table-sftp")
                  callback()
                  toast.success("Member berhasil dihapus")
                } catch (error) {
                  if (error.response && error.response.data && error.response.data.detail) {
                    toast.error(error.response.data.detail)
                  } else {
                    toast.errorRequest(error)
                  }
                  callback()
                }

              }
            })
          },
            true)
        ]}
        onFetchData={async (query) => {
          return getDataSftp(query)
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
      <AddSftp
        visible={showForm}
        onRequestClose={() => setShowForm(false)}
        item={selectedItem} />
    </>
  )
}

export default inject("modalStore")(observer(TableSftp))

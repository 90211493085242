import { crud, http } from "../../../libs/react-mpk/services";

let service = new crud("/api/admin/proxy");

export const getIsSaveFile = () => {
  return http.get(`/api/sb/bp_config`);
};
export const postIsSaveFile = (data) => {
  return http.post(`/api/sb/bp_config`, data);
};
export const putIsSaveFile = (data) => {
  return http.put(`/api/sb/bp_config`, data);
};

export default service;

import { crud, http } from "../../../libs/react-mpk/services";

let service = new crud("/api/admin/user");

export const getDataUserNpwp = (query) => {
  return http.get(`/api/admin/user_npwp`, query);
};
export const addDataUserNpwp = (data) => {
  return http.post(`/api/admin/user_npwp`, data);
};
export const getDataUserNpwpById = (id, query) => {
  return http.get(`/api/admin/user_npwp/${id}`, query);
};
export const getDataUserNpwpByIdUser = (id_user, query) => {
  return http.get(`/api/admin/user_npwp/${id_user}`, query);
};
export const editDataUserNpwpById = (id, data) => {
  return http.put(`/api/admin/user_npwp/${id}`, data);
};
export const deleteDataUserNpwp = (id, item) => {
  return http.delete(`/api/admin/user_npwp/${id}`, item);
};




export default service;

import React, { useState, useEffect, Children } from "react";
import { DataForm } from "../../../libs/react-mpk/components";
import { TableWrapper } from "../../../libs/react-mpk/wrapper"
import { toast } from "../../../libs/react-mpk/services"
import { inject, observer } from "mobx-react";
import service, { addDataUserNpwp, editDataUserById, getDataUserNpwp, getDataUserNpwpByIdUser } from "./UserNpwp.service";
import { getDataNpwp } from "../TabelNpwp/TabelNpwp.service";
import _, { find } from "lodash";
import { options } from "superagent";

const { inputTypes } = DataForm;
const FromUserNpwp = ({ visible = false, onRequestClose = null, item, user, ...props }) => {
    const [ready, setReady] = useState(false)
    const [npwp, setNpwp] = useState('')
    const [selectedNpwp, setSelectedNpwp] = useState(null)
    const [npwpList, setNpwpList] = useState([])
    useEffect(async () => {
        const dataNpwp = await getDataNpwp({ page: 0, size: 10 })
        setNpwpList(dataNpwp.data)
        if (visible)
            setReady(true)
    }, [visible])

    const _handleGetNpwp = async (npwp) => {
        try {
            let query = { page: 0, size: 10 }
            query['npwp.contains'] = npwp
            const dataNpwp = await getDataNpwp(query)
            setNpwpList(dataNpwp.data)

        } catch (error) {

        }
    }

    const cekNpwpUser = async (npwp) => {
        try {
            let query = { 'npwpNpwp.equals': npwp }
            query['userId.equals'] = user.id
            let res = await getDataUserNpwp(query)
            if (res.data.length > 0) {
                let filter = _.filter(res.data, function (o) {
                    return o.npwp.npwp === npwp;
                })
                if (filter.length < 1) {
                    return 'no'
                } else {
                    return 'ready'
                }
            } else {
                return 'no'
            }
        } catch (error) {
            console.log("🚀 ~ file: AddUserNpwp.js ~ line 46 ~ cekNpwpUser ~ error", error)
        }

    }
    return ready && (
        <DataForm
            baseId="add-user-npwp"
            title="Tambah NPWP User "
            asDialog={true}
            visible={visible}
            onRequestClose={() => {
                setReady(false)
                onRequestClose()
            }}
            defaultData={{
                user: user?.nama,
                npwp: npwp,
            }
            }
            definitions={[
                {
                    inputType: inputTypes.INPUT,
                    label: "Nama User",
                    key: "user",
                    required: true,
                    readOnly: true,
                },
                {
                    inputType: inputTypes.AUTOCOMPLETE,
                    label: 'NPWP',
                    key: "npwp",
                    value: npwp,
                    data: npwpList.map(d => ({
                        children: d.npwp,
                        value: d.npwp
                    })),
                    required: true
                },
            ]}
            onBeforeChange={(key, value) => {
                if (key === 'npwp') {
                    _handleGetNpwp(value)
                    setNpwp(value)
                }
                return value
            }}
            onChange={(data, key, value) => {
                if (key === 'npwp') {
                    data.npwp = value.toLowerCase()
                    setNpwp(value)
                    let fillter = _.filter(npwpList, { npwp: value })
                    if (fillter.length > 0) {
                        setSelectedNpwp(fillter[0])
                    }
                }
                return data
            }}
            onSubmit={async (data, callback) => {
                if (selectedNpwp) {
                    try {
                        let datas = {
                            // npwp: {...selectedNpwp, expiredDate: `${selectedNpwp.expiredDate.year}-${selectedNpwp.expiredDate.monthValue < 10?'0'+selectedNpwp.expiredDate.monthValue:selectedNpwp.expiredDate.monthValue}-${selectedNpwp.expiredDate.dayOfMonth}`},
                            npwp: selectedNpwp,
                            user: user
                        }
                        let cek_npwp = await cekNpwpUser(selectedNpwp.npwp)
                        if (cek_npwp === 'no') {
                            await addDataUserNpwp(datas)
                            TableWrapper.reload("mod-table-npwp-user")
                            callback(item ? "Data Berhasil Diedit" : "Data Berhasil Ditambah", false)
                        } else {
                            callback("NPWP ini sudah tersedia di user ini", true, false)
                        }
                    } catch (error) {
                        if (error.response && error.response.data && error.response.data.detail) {
                            callback(error.response.data.detail, true, false)
                        } else {
                            callback(error, true, false)
                        }
                    }
                } else {
                    callback('Npwp tidak terpilih', true, false)
                }
            }}
        />
    );
};

export default inject("envStore")(observer(FromUserNpwp));

import React, { useState, useEffect, Children } from "react";
import { DataForm } from "../../../libs/react-mpk/components";
import { TableWrapper } from "../../../libs/react-mpk/wrapper"
import { toast } from "../../../libs/react-mpk/services"
import { inject, observer } from "mobx-react";
import service, { addDataProxy, getDataProxy, editDataProxyById } from "./TabelProxy.service";
import { find } from "lodash";

const { inputTypes } = DataForm;
const AddProxy = ({ visible = false, onRequestClose = null, item, ...props }) => {
    const [ready, setReady] = useState(false)
    useEffect(() => {
        if (visible)
            setReady(true)
    }, [visible])
    return ready && (
        <DataForm
            baseId="add-proxy"
            title="Tambah Proxy"
            asDialog={true}
            visible={visible}
            onRequestClose={() => {
                setReady(false)
                onRequestClose()
            }}
            defaultData={
                item || {
                    url: "",
                    port: "",
                    username: "",
                    password: "",
                }
            }
            definitions={[
                {
                    inputType: inputTypes.INPUT,
                    label: "Url",
                    key: "url",
                    required: true
                },
                {
                    inputType: inputTypes.INPUT,
                    label: "Port",
                    key: "port",
                },
                {
                    inputType: inputTypes.INPUT,
                    label: "Username",
                    key: "username",
                },
                // {
                //     inputType: inputTypes.INPUT,
                //     label: "Password",
                //     key: "password",
                // },
                {
                    inputType: inputTypes.INPUT,
                    label: 'Password',
                    key: "password",
                    // minLength: 8,
                    type: 'password',
                    // required: true,
                    // show: item?false:true
                },
            ]}
            onSubmit={async (data, callback) => {
                try {
                    let dataList = await getDataProxy({ page: 0, size: 50 })
                    if (!item && dataList && dataList.data.length > 0) {
                        callback('Data Setting Proxy hanya bisa di input 1 saja!!!', true)
                    } else {
                        let response = item
                            ? await editDataProxyById(item.id, data)
                            : await addDataProxy(data)
                        TableWrapper.updateDataItem("mod-table-proxy", { id: response.data.id }, response.data)
                        console.log(data);
                        callback(item ? "Data Berhasil Diedit" : "Data Berhasil Ditambah", false)
                    }
                } catch (error) {
                    if (error.response && error.response.data && error.response.data.detail) {
                        callback(error.response.data.detail, true)
                    } else {
                        callback(error, true)
                    }
                }
            }}
        />
    );
};

export default inject("envStore")(observer(AddProxy));

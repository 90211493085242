import React, { useState, useEffect } from "react";
import { DataForm } from "../../../libs/react-mpk/components";
import { TableWrapper } from "../../../libs/react-mpk/wrapper"
import { toast } from "../../../libs/react-mpk/services"
import { inject, observer } from "mobx-react";
import { updateBupotRef } from "./TableBuktiPotong.service"
import { Button, FontIcon } from "react-md";


const { inputTypes } = DataForm;

const TambahReferensi = ({ visible = false, onRequestClose = null, item = null, isEdit = true, authStore, envStore, ...props }) => {
  let isSupport = authStore.user.isSupport
  const [ready, setReady] = useState(false)
  let { field_lampiran_1, field_lampiran_2 } = envStore.env

  const [referensi, setReferensi] = useState([
    {
      inputType: inputTypes.INPUT,
      label: "Referensi 1",
      key: 'ref-0',
      width: '90%',
      required: false
    },
    {
      render: (
        <Button
          buttonType="icon"
          onClick={() => _handleDelete(0)}
          key={`hapus-${0}`}
        >
          <FontIcon iconClassName='mdi mdi-delete' />
        </Button>
      ),
      show: isEdit
    },
    {
      inputType: inputTypes.INPUT,
      label: "Referensi 2",
      key: 'ref-1',
      width: '90%',
      required: false
    },
    {
      render: (
        <Button
          buttonType="icon"
          onClick={() => _handleDelete(1)}
          key={`hapus-${1}`}
        >
          <FontIcon iconClassName='mdi mdi-delete' />
        </Button>
      ),
      show: isEdit
    }
  ])
  const [defData, setDefData] = useState({
    'ref-0': '',
    'ref-1': '',
  })

  const _handleDelete = async (key) => {
    setDefData((e) => {
      let draf = { ...e }
      for (const [keys, value] of Object.entries(draf)) {
        if (`ref-${key}` === keys) {
          delete draf[keys]
        }
      }
      let res = {}
      let obj = Object.keys(draf)
      for (const idx in obj) {
        res[`ref-${idx}`] = draf[obj[idx]]
      }
      return res
    })
    setReferensi((e) => {
      let draf = [...e]
      draf.splice(parseInt(key) * 2, 2)
      let no = 1
      for (const key in draf) {
        if (draf[key].render) {
          draf[key] = {
            render: (
              <Button
                buttonType="icon"
                onClick={() => _handleDelete((key / 2) - 0.5)}
                key={`hapus-${key / 2}`}
              >
                <FontIcon iconClassName='mdi mdi-delete' />
              </Button>
            ),
            show: isEdit
          }
        } else {
          console.log("🚀 ~ file: TambahReferensi.js ~ line 88 ~ setReferensi ~ key / 2", key / 2)
          draf[key] = {
            inputType: inputTypes.INPUT,
            label: `Referensi ${parseInt(no)}`,
            key: `ref-${key / 2}`,
            width: '90%',
            required: false,
            readOnly: !isEdit
          }
          no++
        }
      }
      return draf
    })

  }
  useEffect(() => {
    setReady(false)
    if (item && item.referensi) {
      let arrayRef = item.referensi.split("|")
      let dataref = []
      let defdata = {}
      for (const key in arrayRef) {
        if (arrayRef[key]) {
          dataref.push({
            inputType: inputTypes.INPUT,
            label: `Referensi ${parseInt(key) + 1}`,
            key: `ref-${key}`,
            width: '90%',
            required: false,
            readOnly: !isEdit
          })
          dataref.push({
            render: (
              <Button
                buttonType="icon"
                onClick={() => _handleDelete(key)}
                key={`hapus-${key}`}
              >
                <FontIcon iconClassName='mdi mdi-delete' />
              </Button>
            ),
            show: isEdit
          })
          defdata[`ref-${key}`] = arrayRef[key]
        }
      }
      setDefData(defdata)
      setReferensi(dataref)
    }
    setTimeout(() => {
      setReady(true)
    }, 1000)
  }, [visible])

  const handleAddRef = () => {
    setReferensi((e) => {
      return [...e, {
        inputType: inputTypes.INPUT,
        label: `Referensi ${(e.length / 2) + 1}`,
        key: `ref-${e.length / 2}`,
        required: false,
        width: '90%'
      },
      {
        render: (
          <Button
            buttonType="icon"
            onClick={() => _handleDelete(e.length / 2)}
            key={`hapus-${e.length / 2}`}
          >
            <FontIcon iconClassName='mdi mdi-delete' />
          </Button>
        ),
        show: isEdit
      }
      ]
    })
  }


  return ready && (
    <DataForm
      baseId="update-referensi"
      title={field_lampiran_1}
      style={{
        width: 500,
        heigth: 500,
      }}
      editable={isEdit}
      asDialog={true}
      visible={visible}
      onRequestClose={() => {
        setReady(false)
        onRequestClose()
        setDefData({
          'ref-0': '',
          'ref-1': '',
        })
        setReferensi([
          {
            inputType: inputTypes.INPUT,
            label: "Referensi 1",
            key: 'ref-0',
            width: '90%',
            required: false
          },
          {
            render: (
              <Button
                buttonType="icon"
                onClick={() => _handleDelete(0)}
                key={`hapus-${0}`}
              >
                <FontIcon iconClassName='mdi mdi-delete' />
              </Button>
            ),
            show: isEdit
          },
          {
            inputType: inputTypes.INPUT,
            label: "Referensi 2",
            key: 'ref-1',
            width: '90%',
            required: false
          },
          {
            render: (
              <Button
                buttonType="icon"
                onClick={() => _handleDelete(1)}
                key={`hapus-${1}`}
              >
                <FontIcon iconClassName='mdi mdi-delete' />
              </Button>
            ),
            show: isEdit
          },
        ])
      }}
      defaultData={
        defData
      }
      onInitData={(data) => {
        if (data.status) {
          data.statusId = data.status.id
        }
        return data
      }}
      definitions={[{
        render: (
          <div className="mpk-full full-width mpk-flex justify-end mpk-margin-N margin-bottom">
            <Button theme="primary" themeType="outline" onClick={handleAddRef} > Tambah</Button >
          </div>
        ),
        show: isEdit
      }, ...referensi
      ]}
      onSubmit={async (data, callback, res) => {
        try {
          let dataref = ''
          for (const [key, value] of Object.entries(data)) {
            if (value) {
              dataref += `${value}|`
            }
          }
          await updateBupotRef(item.idBupot, { referensi: dataref })
          TableWrapper.reload("mod-table-bukti-potong-op")
          callback("Referensi Berhasil Diupdate", false)
        } catch (error) {
          if (error.response && error.response.data && error.response.data.detail) {
            callback(error.response.data.detail)
          } else {
            callback(error)
          }
        }
      }}
    />
  );
};

export default inject("envStore", "authStore")(observer(TambahReferensi));

const License = `
<span style='text-align:justify;'>
    <h3><strong><span>Perjanjian Lisensi Pengguna Akhir Pajakku</span></strong></h3>
    <p><span>Terima kasih telah memilih Layanan Aplikasi PT Mitra Pajakku (selanjutnya disebut &ldquo;<strong>Pajakku</strong>&rdquo;). Pajakku sangat menghargai dan mengapresiasi kepercayaan Pengguna untuk memilih Layanan Aplikasi Pajakku. Maka dari itu Pajakku berusaha selalu memberikan layanan yang terbaik demi kepuasan Pengguna dalam menggunakan layanan Pajakku.</span></p>
    <p>Untuk selanjutnya, terdapat Perjanjian Lisensi Pengguna Akhir Pajakku (selanjutnya disebut &quot;<strong>Perjanjian Lisensi</strong>&quot;) yang mengatur mengenai persyaratan yang harus diperhatikan sebelum menggunakan Layanan Aplikasi Pajakku dan menyetujui sebagai penerima lisensi selama penggunaan Layanan Aplikasi Pajakku (selanjutnya disebut&quot;<strong>Pengguna</strong>&quot;). Perjanjian Lisensi ini memberikan perlindungan kepada Pengguna atas penggunaan Layanan Aplikasi Pajakku yang dapat diakses dan digunakan melalui situs web Pajakku dan Aplikasi seluler Konsul Pajak di perangkat Android.
    <p>Perjanjian Lisensi ini tunduk pada ketentuan peraturan perundang-undangan di Indonesia termasuk, namun tidak terbatas pada Undang-Undang Nomor 28 Tahun 2014 tentang Hak Cipta, Undang-Undang Nomor 11 Tahun 2008 tentang Informasi dan Transaksi Elektronik serta perubahannya yaitu Undang-Undang Nomor 19 Nomor 2016, Undang-Undang Nomor 48 Tahun 2009 tentang Kekuasaan Kehakiman, yang dijelaskan lebih mendalam pada Perjanjian Lisensi ini. </p>
    <p>Dengan menerima persyaratan di Perjanjian Lisensi ini Pengguna menyatakan bahwa Pengguna cakap saling mengikat berdasarkan ketentuan Pasal 1320 Kitab Undang-Undang Hukum Perdata Indonesia dan Pengguna menyetujui Persetujuan Lisensi ini. Pengguna menerima persyaratan di Perjanjian Lisensi ini atas nama diri sendiri/entitas manapun yang Pengguna wakili dan/atau atas nama orang Iain yang mengakses/menggunakan Layanan Aplikasi Pajakku pada perangkat yang Pengguna miliki/kendalikan dan/atau menggunakan akun Pengguna pada Layanan Aplikasi Pajakku. Pengguna dan pihak yang diwakilkan oleh pengguna bertanggung jawab atas penggunaan oleh orang lain tersebut dan kepatuhan mereka atas ketentuan- ketentuan di dalam Perjanjian Lisensi ini.</p></p>
    
    <div>
        <ol style="margin-left:13px">
            <li style="margin-bottom:5px"><strong><span>Definisi</span></strong></li>
            <div>
                <p><strong><span>1.1.</span></strong><span> “Jumlah Nomor Pokok Wajib Pajak (NPWP) atau Nomor Identitas Tempat Kegiatan Usaha (NITKU)” adalah banyaknya NPWP atau NITKU yang diberikan oleh Pengguna kepada Pajakku untuk dapat diproses dalam penggunaan produk Layanan Aplikasi Pajakku yang telah dipilih oleh Pengguna.</span></p>
                <p><strong><span>1.2.</span></strong><span> </span>&ldquo;Layanan Aplikasi Pajakku&rdquo; adalah segala layanan secara&nbsp;</span><em><span>end-to-end&nbsp;</span></em><span>dan telah lolos uji dari DJP yang terdiri dari e-Konfirmasi Status Wajib Pajak, Penerimaan Negara Pajakku, e-Pengolahan Pajak Terpadu, Tarra e-Faktur Pajakku, e-Met Pajakku, Sistem Integrasi Perpajakan, Tax Dokumen Center, atau layanan- layanan lain yang Pajakku tawarkan kepada Pengguna dalam bentuk&nbsp;</span><em><span>Application as Product&nbsp;</span></em><span>ataupun&nbsp;</span><em><span>Application as Service&nbsp;</span></em><span>(API/Widget) dalam kondisi&nbsp;</span><em><span>On-Premise&nbsp;</span></em><span>(OP),&nbsp;</span><em><span>On-Premise&nbsp;</span></em><span>(OP)&nbsp;</span><em><span>Hosted</span></em><span>, ataupun&nbsp;</span><em><span>Software as Service&nbsp;</span></em><span>(Saas), dengan layanan yang disebutkan dapat diperbaharui, diubah, dan/atau ditambahkan oleh Pajakku dari waktu ke waktu.</p>
                <p><strong><span>1.3.</span></strong><span> </span>“Lisensi” adalah izin yang diberikan oleh Pajakku kepada Pengguna untuk menggunakan Layanan Aplikasi Pajakku yang memiliki tenggat waktu.</p>
                <p><strong><span>1.4.</span></strong><span> </span>“Pajakku” adalah pemilik Layanan dan pemberi Lisensi dalam Perjanjian Lisensi ini.</p>
                <p><strong><span>1.5.</span></strong><span> </span>“Pembaruan” adalah setiap peningkatan, penambahan, pengurangan, atau penggantian Layanan Aplikasi Pajakku oleh Pajakku.</p>
                <p><strong><span>1.6.</span></strong><span> </span>“Pengguna” adalah setiap individu dan/atau badan usaha atau badan hukum yang menggunakan atau mengoperasikan atau mengakses Layanan Aplikasi Pajakku, termasuk namun tidak terbatas pada karyawan atau Pihak yang ditunjuk untuk mewakili badan usaha atau badan hukum untuk menaati ketentuan dalam Perjanjian Lisensi ini, Kebijakan Privasi, Syarat dan Ketentuan, Perjanjian Kerja Sama, dan/atau dokumen lain yang saling mengikat antara Pengguna dan Pajakku.</p>
                <p><strong><span>1.7.</span></strong><span> </span>“Periode berlangganan” adalah jangka waktu Pengguna dapat menggunakan Layanan Aplikasi Pajakku yang telah disepakati pada Perjanjian Kerja Sama yang ditandatangani antara Pengguna dengan Pajakku.</p>
                <p><strong><span>1.8.</span></strong><span> </span>“Perjanjian Lisensi” adalah perjanjian yang mengikat antara Pajakku dengan Pengguna untuk menjelaskan dan menetapkan persyaratan penggunaan Layanan Aplikasi Pajakku.Pembaruan dapat mencakup tidak terbatas pada penambahan dan/atau penghilangan fitur atau fungsi tertentu yang ditawarkan oleh Layanan Aplikasi Pajakku, atau dapat menggantikannya secara menyeluruh.</p>
                <p><strong><span>1.9.</span></strong><span> </span>“Sengketa” adalah setiap permasalahan, perselisihan, tindakan, klaim, atau kontroversi antara Pengguna dan Pajakku sehubungan dengan penggunaan Layanan Aplikasi Pajakku baik berdasarkan kontra undang- undang regulasi, ketentuan perbuatan melawan hukum (termasuk pemalsuan, pernyataan palsu, penipuan atau kelalaian).</p>
                <p><strong><span>1.10.</span></strong><span> </span>“Kuota” adalah jumlah batas maksimal atau minimal yang diberikan oleh Pajakku kepada Pengguna terkait batasan sesuai dengan Layanan Aplikasi Perpajakan yang telah dipilih dan disepakati oleh Pengguna dan Pajakku.</p>
                <p><strong><span>1.11.</span></strong><span> </span>“Ketentuan yang Berlaku” adalah segala hal yang termasuk di dalam Perjanjian Lisensi ini, Kebijakan Privasi, Syarat dan Ketentuan, Perjanjian Kerja Sama, dan/atau dokumen lain yang saling mengikat antara Pengguna dan Pajakku.</p>
            </div>
            <br/>
            <li style="margin-bottom:5px"><strong><span>Lisensi</span></strong></li>
            <div>
                <p><strong><span>2.1.</span></strong><span> Pajakku dengan ini meminjamkan Pengguna hak/lisensi yang terbatas, tidak dapat dialihkan, dan tidak komersial untuk menggunakan Layanan Aplikasi Pajakku selama periode yang telah disepakati dalam Ketentuan yang Berlaku, termasuk semua perpanjangan atau pembaruan periode yang disepakati (&ldquo;<strong>Periode Berlangganan</strong>&rdquo;), serta kuota, dan hal lainnya dengan ketentuan Pengguna menyetujui Perjanjian Lisensi ini. Layanan Aplikasi Pajakku tidak dapat dipindahtangankan kepada Pihak Ketiga di luar afiliasinya</span></p>
                <p><strong><span>2.2.</span></strong><span> Pajakku memberi Pengguna hak/lisensi komersial terpisah kepada pihak tertentu untuk menggunakan Layanan Aplikasi Pajakku selama periode yang telah disepakati dalam Ketentuan yang berlaku.</span></p>
                <p><strong><span>2.3.</span></strong><span> Seluruh hak untuk menggunakan Layanan Aplikasi Pajakku diberikan hanya berdasarkan lisensi dan Pengguna tidak diberikan hak kepemilikan atau kepentingan apapun atas Layanan Aplikasi Pajakku. Seluruh akses serta penggunaan dari Pengguna terhadap Layanan Aplikasi Pajakku ini patuh pada ketentuan Perjanjian Lisensi ini termasuk, namun tidak terbatas pada Undang-Undang No. 28 Tahun 2014 tentang Hak Cipta.</span></p>
                <p><strong><span>2.4.</span></strong><span> Setelah masa berlangganan atau kuota penggunaan berakhir, maka secara otomatis hak/lisensi tersebut sebagaimana disebutkan di Pasal 2.1 akan kembali kepada Pajakku, serta Pajakku berhak menutup akun yang digunakan oleh Pengguna.</span></p>
            </div>
            <br/>
            <li style="margin-bottom:5px"><strong><span>Penggunaan Layanan Aplikasi Pajakku</span></strong></li>
            <div>
                <p><strong><span>3.1.</span></strong><span> Penggunaan Layanan Aplikasi Pajakku oleh Pengguna selain yang diizinkan pada Perjanjian Lisensi ini dan telah disetujui oleh Pajakku merupakan pelanggaran atas Perjanjian Lisensi ini sesuai Pasal 9 ayat(3) Undang-Undang Nomor 28 tahun 2014 tentang Hak Cipta.</span></p>
                <p><strong><span>3.2.</span></strong><span> Dalam hal Layanan Aplikasi Pajakku diperuntukkan bagi penggunaan perusahaan, konsultan, <em>freelance </em>ataupun suatu lembaga maka Pengguna bertanggung jawab atas kepatuhan perusahaan terhadap Perjanjian Lisensi ini, dan pelanggaran yang dilakukan oleh Perusahaan, konsultan, <em>freelance </em>atau suatu Lembaga tersebut akan dianggap sebagai pelanggaran Pengguna yang wajib dipertanggungjawabkan oleh Perusahaan, konsultan, <em>freelance </em>atau Lembaga yang menaunginya.</span></p>
                <p><strong><span>3.3.</span></strong><span> Pihak Ketiga yang menggunakan Layanan Aplikasi Pajakku juga memiliki hak dan kewajiban yang sama.</span></p>
            
            </div>
            <br/>
            <li style="margin-bottom:5px"><strong><span>Hak Kepemilikan</span></strong></li>
            <div>
            <p><strong><span>4.1.</span></strong> Layanan Aplikasi Pajakku merupakan kepunyaan dan milik Pajakku yang termasuk bagian dari kekayaan intelektual Pajakku yang dilindungi oleh undang-undang, sebagaimana diatur dalam Penjelasan I Bagian Umum Undang-Undang Nomor 28 tahun 2014 tentang Hak Cipta. Struktur organisasi, bisnis proses, arsitektur serta kode komputer perangkat lunak atau keras apapun merupakan rahasia dagang, dan informasi rahasia milik Pajakku.</p>
            <p><strong><span>4.2.</span></strong> Semua hak atas Layanan Aplikasi Pajakku (semua hak cipta, paten, hak rahasia dagang, merek dagang, dan hak kekayaan intelektual lain yang terkait) adalah milik Pajakku dan Pengguna hanya memillki hak untuk memakainya.</p>
            <p><strong><span>4.3.</span></strong> Setiap ide, komentar, kritik atau saran termasuk namun tidak terbatas pada informasi yang disampaikan oleh Pengguna terkait Layanan Aplikasi Pajakku, dianggap bahwa Pengguna memberikan hak dan lisensi kepada Pajakku untuk menyimpan dan menggunakan hal tersebut yang telah disebutkan sebelumnya untuk tujuan apapun terkait produk atau Layanan Aplikasi Pajakku, tanpa memberikan kompensasi kepada Pengguna dan tanpa persetujuan Pengguna atas penyimpanan atau penggunaan tersebut.</p>
            </div>
            <br/>
            <li style="margin-bottom:5px"><strong><span>Pembaruan</span></strong></li>
            <div>
                <p><strong><span>5.1.</span></strong> Selama Periode Berlangganan, dengan tanpa pemberitahuan dari Pajakku kepada Pengguna, Pajakku dapat melakukan Pembaruan.</p>
                <p><strong><span>5.2.</span></strong> Apabila Pengguna sudah menggunakan versi terbaru dari Layanan Aplikasi Pajakku, Pengguna tidak lagi dapat menggunakan versi Layanan Aplikasi sebelumnya.</p>
            </div>
            <br/>
            <li style="margin-bottom:5px"><strong><span>Pemberhentian atau Pengakhiran, dan Penghapusan Akun</span></strong></li>
            <div>
                <p>Pajakku dapat melakukan pemberhentian, pengakhiran, dan/atau penghapusan akun lisensi jika:</p>
                <p><strong><span>6.1.</span></strong> Pengguna melanggar Perjanjian Lisensi ini, Kebijakan Privasi, Syarat dan Ketentuan, Perjanjian Kerja Sama, dan/atau dokumen lain yang saling mengikat antara Pengguna dan Pajakku;</p>
                <p><strong><span>6.2.</span></strong> Diharuskan untuk dicabut berdasarkan hukum atau undang-undang yang berlaku atau ada instruksi yang diberikan dari Pemerintahan kepada Pajakku yang mengharuskan suatu Layanan Aplikasi Pajakku dicabut;</p>
                <p><strong><span>6.3.</span></strong> Pajakku memutuskan bahwa Pengguna telah melanggar ketentuan-ketentuan di dalam Perjanjian Lisensi ini sehingga menyebabkan sengketa, maka Pajakku dapat mengambil tindakan apapun untuk melindungi kepentingan-kepentingannya seperti penutupan akses atau penggunaan atas sebagian atau keseluruhan Layanan Aplikasi Pajakku; dan</p>
                <p><strong><span>6.4.</span></strong>Pajakku berwenang untuk melakukan tindakan hukum dalam hal terjadl pelanggaran terhadap Perjanjian Lisensi in</p>
            </div>
            <br/>
            <li style="margin-bottom:5px"><strong><span>Larangan</span></strong></li>
            <div>
                <p>Selama menggunakan dan/atau pengakhiran kerjasama atas Layanan Aplikasi Pajakku, Pengguna dilarang secara sengaja maupun tidak sengaja, untuk :</p>
                <p><strong><span>7.1.</span></strong> Melisensikan kembali, memodifikasi, mengubah, replikasi, duplikasi, mengadaptasi atau menerjemahkan bagian apapun dari Layanan Aplikasi Pajakku;</p>
                <p><strong><span>7.2.</span></strong> Membuat produk turunan, mencoba untuk menciptakan kode pemograman, menggunakan perangkat keras atau perangkat lunak yang tidak resmi, ilegal, palsu, atau yang telah termodifikasi dari Layanan Aplikasi Pajakku yang sekiranya dapat merugikan maupun tidak merugikan Pajakku secara komersial;</p>
                <p><strong><span>7.3.</span></strong> Memanfaatkan hak cipta yang dimiliki oleh Pajakku atau melakukan <em>plagiarisme </em>terhadap Layanan Aplikasi Pajakku sesuai dengan Pasal 44 Undang-Undang Nomor 28 tahun 2014 tentang Hak Cipta;</p>
                <p><strong><span>7.4.</span></strong> Melakukan hal-hal yang merugikan negara dengan menggunakan Layanan Aplikasi Pajakku;</p>
                <p><strong><span>7.5.</span></strong> Melanggar segala hukum, peraturan atau undang-undang yang berlaku atau hak-hak dari Pajakku atau Pihak Ketiga sehubungan dengan akses atau penggunaan Pengguna Akhir atas Layanan Aplikasi Pajakku;</p>
                <p><strong><span>7.6.</span></strong> Menyalahgunakan, mengubah, menyalin, meniru, membongkar, pemasangan BOT atau melakukan <em>reverse engineering </em>atas layanan aplikasi Pajakku yang digunakan sesuai dengan larangan yang diatur dalam Pasal 32 &mdash;35 Undang-Undang Nomor 11 Tahun 2008 tentang Informasi dan Transaksi Elektronik;</p>
                <p><strong><span>7.7.</span></strong> Melakukan segala jenis tindakan baik itu secara langsung maupun tidak langsung yang dapat mengakibatkan kerusakan dalam bentuk apapun pada data center/hardware milik Pajakku; dan</p>
                <p><strong><span>7.8.</span></strong> Menggunakan Layanan Aplikasi Pajakku apabila Pengguna belum membayarkan biaya yang telah disepakati sesuai dengan masa berlangganan, serta kuota yang disepakati.</p>
            </div>
            <br/>
            <li style="margin-bottom:5px"><strong><span>Batasan Tanggung Jawab Pajakku</span></strong></li>
            <div>
                <p><strong><span>8.1.</span></strong><span> Layanan Aplikasi Pajakku diberikan tanpa adanya jaminan-jaminan baik yang dinyatakan secara tegas maupun tersirat bahwa penggunaan terhadap Layanan Aplikasi Pajakku akan selalu sesuai dengan harapan Pengguna, selalu ada dalam kualitas terbaik atau terbebas dari virus, kerusakan, <em>bug, error</em>, kehilangan data, dan gangguan lainnya dan Pengguna melepaskan Pajakku dari tuntutan sehubungan dengan terjadinya hal-hal tersebut.</p>
                <p><strong><span>8.2.</span></strong><span> Berhubungan dengan ketentuan dari Pasal 1 Pajakku akan tetap memberikan pelayanan dan dukungan terbaik untuk setiap kendala yang mungkin timbul sehingga dapat segera terselesaikan dengan baik.</p>
                <p><strong><span>8.3.</span></strong><span> Dalam keadaan apapun (secara langsung, tidak langsung, insidental, khusus atau sebagai akibat) Pajakku tidak bertanggung jawab atas segala bentuk kehilangan data, kehilangan keuntungan atau bentuk-bentuk kehilangan dan kerusakan lainnya yang terjadi akibat kesalahan atau kelalaian Pengguna.</p>
                <p><strong><span>8.4.</span></strong><span> Pajakku tidak bertanggung jawab atas adanya kesalahan input data, keterlambatan pelaporan keterlambatan pembayaran pajak, serta kesalahan pembubuhan materai yang terjadi akibat kesalahan atau kelalaian Pengguna.</p>
                <p><strong><span>8.5.</span></strong><span> Pajakku tidak bertanggung jawab atas sanksi yang dikeluarkan oleh otoritas negara akibat kesalahan atau kelalaian Pengguna.</p>
                <p><strong><span>8.6.</span></strong><span> Pembatasan-pembatasan ini ditafsirkan untuk berlaku seluas-luasnya sejauh mana diperbolehkan berdasarkan hukum yang berlaku di Indonesia.</p>
            </div>
            <br/>
            <li style="margin-bottom:5px"><strong><span>Pemrosesan Data Pribadi Pengguna</span></strong></li>
            <div>
                <p>Pemrosesan Data Pengguna dituliskan secara lengkap dalam dokumen terpisah yaitu pada Kebijakan Privasi yang merupakan satu kesatuan dengan Perjanjian Lisensi ini. Pengguna diharapkan telah membaca terlebih dahulu secara seksama seluruh ketentuan dalam Kebijakan Privasi dan menyetujui konten dalam Perjanjian Lisensi ini. Permrosesan Data Pribadi Pengguna yang dilakukan oleh Pajakku telah memperhatikan dan menyesuaikan dengan prinsip Perlindungan Data Pribadi dan ketentuan peraturan perundang-undangan yang berlaku.</p>
            </div>
            <br/>
            <li style="margin-bottom:5px"><strong><span>Domisili Hukum dan Penyelesaian Perselisihan</span></strong></li>
            <div>
                <p><strong><span>10.1.</span></strong><span> Syarat dan Ketentuan ini dilaksanakan menurut ketentuan hukum yang berlaku di Negara Kesatuan Republik Indonesia, tanpa memperhatikan penentangan aturan hukum.</p>
                <p><strong><span>10.2.</span></strong><span> Pengguna setuju bahwa tindakan hukum apapun atau sengketa yang mungkin timbul darl berhubungan dengan, atau berada dalam cara apapun berhubungan dengan Pajakku dan/atau Perjanjian Lisensi ini akan diselesaikan secara eksklusif dalam yurisdiksi pengadilan Republik Indonesia.</p>
                <p><strong><span>10.3.</span></strong><span> Dengan mengesampingkan ketentuan pada Pasal 10.1 dan 10.2 di atas, apabila terdapat hubungan kerja sama antara Pajakku dan Pengguna yang menggunakan ketentuan hukum yang berlaku di luar hukum Negara Kesatuan Republik Indonesia, maka hal tersebut diperbolehkan sejauh disepakati oleh pihak Pajakku dan Pengguna.</p>
            </div>
            <br/>
            <li style="margin-bottom:5px"><strong><span>Pembebasan Tanggung Jawab Terhadap Pihak Ketiga</span></strong></li>
            <div>
                <p>Pengguna menyatakan dan menjamin untuk membebaskan seluruh otoritas Negara Kesatuan Republik Indonesia dari segala tuntutan yang berkait dengan penyediaan Layanan Aplikasi Pajakku.</p>
            </div>
            <br/>
            <li style="margin-bottom:5px"><strong><span>Lain-Lain</span></strong></li>
            <div>
                <p><strong><span>12.1.</span></strong><span> Apabila ada ketentuan pada Perjanjian ini yang menjadi tidak sah legal atau tidak dapat dilaksanakan maka keberlakuan, legalitas dan pelaksanaan ketentuan- ketentuan lainnya dari Perjanjian ini tidak terkena dampak dan dinyatakan tetap berlaku.</p>
                <p><strong><span>12.2.</span></strong><span> Pengguna menyetujui bahwa pelanggaran terhadap Perjanjian Lisensi ini akan menyebabkan kerugian dan tidak dapat hanya diperbaiki dengan ganti rugi secara materiil, melainkan perlu adanya penggantian secara immateriil (merusak nama baik atau reputasi Pajakku baik secara sengaja maupun tidak disengaja) namun Pajakku juga berhak atas penggantian-penggantian lainnya yang tersedia berdasarkan hukum.</p>
                <p><strong><span>12.3.</span></strong><span> Dalam hal terjadi pelanggaran terhadap Perjanjian Lisensi ini yang dilakukan Pengguna dan merugikan Pajakku, maka Pajakku berhak untukmelaksanakan asas peradilan sesuai dengan Pasal 2 ayat (4) Undang-Undang Nomor 48 Tahun 2009 tentang Kekuasaan Kehakiman yang dilnterpretasi di dalam Kitab Undang-Undang Hukum Acara Pidana (KUHAP) ataupun menyampaikan ganti kerugian yang menjadi tanggung jawab Pengguna kepada Pajakku, mana yang disepakati oleh pihak Pajakku dan Pengguna.</p>
            </div>
        </ol>
    </div>
    <br/>
    <p>Pajakku memiliki hak untuk melakukan perubahan dari waktu ke waktu atas Perjanjian Lisensi ini. Atas segala perubahan, penghapusan, modifikasi, dan lainnya yang dilakukan berdasarkan pada perubahan peraturan perundang-undangan, peraturan lembaga terkait yang berwenang ataupun perubahan praktik pengolahan data yang dilakukan oleh Pajakku. Oleh karenanya, Pengguna diharapkan selalu membaca Perjanjian Lisensi ini melalui situs web&nbsp;</span><a href="http://www.pajakku.com/"><span>www.pajakku.com.</span></a></p>
    <br/>
    <p>Pajakku mengharuskan Pengguna menyetujui perubahan Perjanjian Lisensi ini agar dapat terus menggunakan semua Layanan Aplikasi Pajakku yang telah Pengguna beli sebelumnya, Apabila Pengguna menolak menerima perubahan Perjanjian Lisensi ini, Pajakku dapat mengakhiri penggunaan atas Layanan Aplikasi Pajakku yang terdampak.</p>
    <br/>
    <p>Dengan ini Pengguna menyatakan telah membaca, mengerti dan setuju untuk terikat pada Perjanjian Lisensi ini serta perubahannya.</p>
    <br/>
    <p>Saya (Pengguna) Setuju</p>
    <br/>
    <p><strong><span>Kontak</span></strong></p>
    <p><span>Apabila Pengguna memiliki keberatan atau pertanyaan lebih lanjut berkaitan dengan Perjanjian Lisensi ini maka Pengguna dapat menghubungi Pajakku melalui&nbsp;</span><a href="mailto:marketing@pajakku.com"><span>marketing@pajakku.com.</span></a></p>
    <br/>
    <p>Diperbaharui pada tanggal : 22 Juli 2024</p>


</span>
`

export default License
import React, { useState, useEffect } from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service, { getDataProxy, deleteDataProxy } from './TabelProxy.service'
import moment from 'moment'
import { inject, observer } from "mobx-react";
import AddProxy from "./AddProxy";
import { toast } from '../../../libs/react-mpk/services'

const TableProxy = ({
  className = '',
  showCommandbar = true,
  title,
  ...props
}) => {
  let [showForm, setShowForm] = useState(false);
  let [selectedItem, setSelectedItem] = useState(null)

  return (
    <>
      <TableWrapper
        baseId="mod-table-proxy"
        title="Proxy List"
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        useCriteria={true}
        useFilter={false}
        columns={[
          {
            label: 'Url',
            sortable: true,
            searchable: true,
            onclick: item => {
              console.log('klik');
            },
            render: item => item.url
          },
          {
            label: 'Port',
            sortable: true,
            searchable: true,
            render: item => item.port

          },
          {
            label: 'Username',
            sortable: true,
            searchable: true,
            render: item => item.username
          },
          {
            label: 'Password',
            sortable: true,
            searchable: true,
            render: item => item.password
          },
        ]}
        actions={[
          new TableWrapper.action('Add', 'mdi mdi-plus', () => {
            setSelectedItem(null)
            setShowForm(true)
          }, true)
        ]}
        itemActions={[
          new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => {
            setShowForm(true)
            setSelectedItem(item)
          }, true),
          new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => {
            props.modalStore.showConfirm({
              title: "Delete",
              children: "yakin?",
              onSubmit: async (callback) => {
                try {
                  await deleteDataProxy(item.id)
                  TableWrapper.reload("mod-table-proxy")
                  callback()
                  toast.success("Member berhasil dihapus")
                } catch (error) {
                  if (error.response && error.response.data && error.response.data.detail) {
                    toast.errorRequest(error.response.data.detail)
                  } else {
                    toast.errorRequest(error)
                  }
                  callback()
                }

              }
            })
          },
            true)
        ]}
        onFetchData={async (query) => {
          return getDataProxy(query)
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
      <AddProxy
        visible={showForm}
        onRequestClose={() => setShowForm(false)}
        item={selectedItem} />
    </>
  )
}

export default inject("modalStore")(observer(TableProxy))

import React, { useEffect } from "react";
import { Appbar, Flex, NewsTicker, Sidebar } from "../components";
import { inject, observer } from "mobx-react";

const PageWrapper = ({
  appbarStyle = {},
  baseId = `mpk-page-wrapper`,
  brand = null,
  sidebarCollapsible = false,
  sidebarDefaultCollapse = true,
  sidebarHeader = null,
  sidebarMenu = null,
  inverseTheme = false,
  useSidebar = true,
  showNewsTicker = true,
  handleLogout = null,
  handleProfile = null,
  handleApplicationInfo = null,
  username = null,
  appbar = {},
  commandbar = {},
  sidebar = {},
  customAppbar = null,
  rightSidebar = false,
  customSidebar = null,
  additionalTopbar = null,
  subname = null,
  ...props
}) => {
  const sidebarContent = useSidebar ? (
    customSidebar ? (
      customSidebar
    ) : (
      <Sidebar
        baseId={`${baseId}-sidebar`}
        collapsible={sidebarCollapsible}
        defaultCollapse={sidebarDefaultCollapse}
        header={sidebarHeader}
        menu={sidebarMenu}
        {...sidebar}
      />
    )
  ) : null;

  return (
    <Flex
      className="mpk-full full-width full-height"
      direction={Flex.properties.direction.COLUMN}
    >
      <Appbar
        subname={subname}
        brand={brand}
        inverseTheme={inverseTheme}
        style={appbarStyle}
        handleLogout={handleLogout}
        handleProfile={handleProfile}
        username={username}
        customAppbar={customAppbar}
        handleApplicationInfo={handleApplicationInfo}
        {...appbar}
      />
      <Flex className="full-width" style={{ width: "100%" }}>
        {additionalTopbar}
      </Flex>
      <Flex fit className="mpk-full full-width">
        {!rightSidebar && sidebarContent}
        {/* {(useSidebar && !rightSidebar && !customSidebar) && (
          <Sidebar
            baseId={`${baseId}-sidebar`}
            collapsible={sidebarCollapsible}
            defaultCollapse={sidebarDefaultCollapse}
            header={sidebarHeader}
            menu={sidebarMenu}
            {...sidebar}
          />
        )}
        {(useSidebar && !rightSidebar && customSidebar) && (
          <>
            {customSidebar}
          </>
        )} */}

        <Flex
          direction={Flex.properties.direction.COLUMN}
          fit
          className={`mpk-full full-width full-height mpk-position position-relative`}
        >
          <Flex scrollable={true} fit className={`mpk-full full-width`}>
            {props.children}
          </Flex>
          {showNewsTicker && (
            <NewsTicker className="mpk-border thin solid dark border-top" />
          )}
        </Flex>

        {rightSidebar && sidebarContent}
        {/* {(useSidebar && rightSidebar && !customSidebar) && (
          <Sidebar
            baseId={`${baseId}-sidebar`}
            collapsible={sidebarCollapsible}
            defaultCollapse={sidebarDefaultCollapse}
            header={sidebarHeader}
            menu={sidebarMenu}
            {...sidebar}
          />
        )}
        {(useSidebar && rightSidebar && customSidebar) && (
          <>
            {customSidebar}
          </>
        )} */}
      </Flex>
    </Flex>
  );
};

export default inject("envStore")(observer(PageWrapper));

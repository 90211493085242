import React from 'react'
import { TextField } from 'react-md'
import './CustomTextField.scss'

const CustomTextField = ({
  id              = 'mpk-custom-text-field',
  className       = '',
  helpText        = null,
  errorMessage    = null,
  containerStyle  = {},
  label, value, onChange, defaultValue,
  ...props 
}) => {
  return (
    <div 
      className={`mpk-custom-text-field ${className}`}
      style={containerStyle}
    >
      <TextField
        {...{id, label, value, onChange, defaultValue}}
        {...props}
      />
      { errorMessage ? (
        <div className="message error-text">{errorMessage}</div>
      ) : (
        helpText ? (
          <div className="message help-text">{helpText}</div>
        ) : null
      )}
    </div>
  )
}

export default CustomTextField

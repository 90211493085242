const Privacy = `
<span style='text-align:justify;'>
    <h3><strong><span>Kebijakan Privasi Pajakku</span></strong></h3>
    <p>Pajakku sangat menghormati privasi Pengguna dan selalu berupaya untuk melindungi data yang diberikan oleh Pengguna dalam mengakses Platform dan/atau menggunakan Fitur yang terdapat dalam Layanan Aplikasi PT Mitra Pajakku dan afiliasinya (&ldquo;<strong>Pajakku</strong>&rdquo;). Pajakku melakukan perlindungan data Pengguna dengan mengacu pada peraturan-peraturan hukum yang berlaku di Indonesia termasuk namun tidak terbatas pada Undang-Undang No. 11 tahun 2008 serta perubahannya yaitu Undang-Undang No. 19 tahun 2016 tentang Informasi dan Transaksi Elektronik, Undang &ndash; Undang No. 8 tahun 1999 tentang Perlindungan Konsumen, Permenkominfo No. 20 tahun 2016 tentang Perlindungan Data Pribadi dalam Sistem Elektronik, The EU <em>General</em> <em>Data</em> <em>Protection</em> <em>Regulation,</em> serta peraturan perundang-undangan lainnya yang akan diterbitkan yang berhubungan dengan ketentuan ini.</p>
    <p>Kebijakan Privasi Pajakku ini (&ldquo;<strong>Kebijakan Privasi</strong>&rdquo;) dibuat untuk memberitahu dan menjelaskan kepada Pengguna mengenai aturan yang menjadi satu kesatuan dengan Perjanjian Lisensi, Syarat dan Ketentuan, dan Perjanjian Kerja Sama yang dijadikan dasar atas segala kegiatan Pajakku dalam hal memperoleh, mengumpulkan, mengolah, menganalisis, menampilkan, mengirimkan, menyimpan, mengubah, menghapus, mengelola dan/atau mempergunakan Data Pribadi Pengguna atau informasi pribadi Pengguna yang disampaikan Pengguna baik diminta maupun pada saat menggunakan Layanan Aplikasi Pajakku melalui Aplikasi seluler dan/atau Website Pajakku (secara bersama-sama disebut &ldquo;<strong>Platform</strong>&rdquo;). Pengguna yang dalam hal ini merupakan setiap individu dan/atau badan usaha atau badan hukum yang menggunakan atau mengoperasikan atau mengakses Layanan Aplikasi Pajakku, termasuk namun tidak terbatas pada karyawan atau Pihak yang ditunjuk untuk mewakili badan usaha atau badan hukum untuk menaati ketentuan dalam Perjanjian Lisensi, Kebijakan Privasi, Syarat dan Ketentuan, Perjanjian Kerja Sama, dan/atau dokumen lain yang saling mengikat antara Pengguna dan Pajakku (&ldquo;<strong>Pengguna</strong>&rdquo;). Data Pribadi Pengguna dapat diberikan kepada beberapa penyedia jasa, antara lain penyelenggara jasa sistem pembayaran, regulator, lembaga penegak hukum, dan pakar eksternal seperti auditor, advokat, dan konsultan lainnya yang Pajakku tunjuk untuk menjalankan operasi kegiatan Pajakku (&ldquo;<strong>Pihak Ketiga</strong>&rdquo;).</p>
    <p>Pajakku memiliki hak untuk melakukan perubahan dari waktu ke waktu atas Kebijakan Privasi ini. Atas segala perubahan, penghapusan, modifikasi, dan lainnya yang dilakukan berdasarkan pada perubahan peraturan perundang-undangan, peraturan lembaga terkait yang berwenang ataupun perubahan praktik pengolahan data yang dilakukan oleh Pajakku. Oleh karenanya, Pengguna diharapkan selalu membaca Kebijakan Privasi ini melalui situs web <a href="http://www.pajakku.com/">www.pajakku.com</a>. Berdasarkan Kebijakan Privasi ini, Pengguna mengakui bahwa telah memahami ketentuan dalam Kebijakan Privasi ini dan telah memberikan persetujuan kepada Pajakku untuk memperoleh, mengumpulkan, menganalisis, menyimpan, mengelola dan/atau mempergunakan data tersebut sesuai tujuan sebagaimana tercantum dalam Kebijakan Privasi.</p>

    <div>
        <ol style="margin-left:13px">
          <li style="margin-bottom:5px"><strong><span>Data Pengguna</span></strong></li>
            <div>
                <span><strong><span>1.1. Data Pribadi Pengguna</span></strong><span>
                <p>Data Pribadi mengacu pada segala data, informasi, dan/atau keterangan dalam bentuk apapun yang dapat mengidentifikasikan diri Pengguna baik secara tersendiri atau dikombinasi dengan informasi lainnya baik secara langsung maupun tidak langsung melalui sistem elektronik dan non elektronik, antara lain termasuk tetapi tidak terbatas pada:</p>
                <p>nama lengkap, nomor identitas, nomor NPWP atau NITKU, alamat, alamat e-mail, nomor <em>handphone </em>atau telepon, dan nama Pengguna <em>(username)</em>, alamat IP (lokasi), <em>cookie</em>, informasi perbankan, klasifikasi perpajakan, dan Data Pribadi lainnya selain daripada yang disebutkan diatas untuk diberikan kepada Pajakku dari waktu ke waktu sesuai kebutuhan penggunaan Layanan Aplikasi Pajakku dan ketentuan dalam Kebijakan Privasi ini. Data Pribadi yang disampaikan wajib dimuat secara benar, jelas, akurat, dan lengkap guna dibutuhkan dalam memproses segala bentuk permintaan, aktivitas maupun transaksi yang dilakukan Pengguna dalam menggunakan Layanan Aplikasi Pajakku, termasuk namun tidak terbatas pada proses Perpajakan. Apabila Pengguna tidak memberikan Data Pribadi yang disebutkan diatas, atau memberikan pernyataan dan jaminan atas informasi atau Data Pribadi yang tidak benar, tidak jelas, tidak akurat, atau tidak lengkap, maka Pajakku berhak menolak permohonan pembuatan akun Pajakku untuk Pengguna, dan menangguhkan atau memberhentikan sebagian atau seluruh Layanan Aplikasi Pajakku yang diberikan kepada Pengguna.</p>
                <span><strong><span>1.2. Perolehan dan Pengumpulan Data Pribadi Pengguna</span></strong><span>
                <p>Pajakku memperoleh data Pengguna pada saat Pengguna mendaftarkan diri dan mulai mengakses Layanan Aplikasi Pajakku, yang berkaitan dengan:</p>
                <div style="margin-left:23px">
                    <span><strong><span>1.2.1.</span></strong><span> Informasi Lisensi/Langganan</span></span>
                    <p>Data yang diperoleh termasuk namun tidak terbatas pada produk atau layanan yang digunakan oleh Pengguna, periode berlangganan, kuota dalam penggunaan Layanan Aplikasi Pajakku, cara pembayaran, dan <em>cookie</em>. Data ini diperlukan sebagai referensi bagi Pajakku untuk memantau produk dan layanan yang Pajakku berikan untuk Pengguna.</p>                                    
                    <span><strong><span>1.2.2.</span></strong><span> Informasi Produk/Langganan</span></span>
                    <p>Data yang diperoleh termasuk namun tidak terbatas pada bagaimana Pengguna mengakses Layanan Aplikasi Pajakku, berapa lama waktu yang dibutuhkan oleh Pengguna untuk mengakses produk dan Layanan Aplikasi Pajakku, maupun fitur yang paling sering Pengguna gunakan. Data ini diperlukan untuk mengembangkan Layanan Aplikasi Pajakku agar dapat terus berkembang sesuai dengan kebutuhan dan keinginan Pengguna.</p>
                    <span><strong><span>1.2.3.</span></strong><span> Peristiwa Sistem Operasi/Langganan</span></span>
                    <p>Data Pribadi yang diperoleh dikumpulkan secara otomatis seperti <em>IP Address, login information, geolocation, browser client &amp; version, timestamp of activities, operating system, </em>termasuk namun tidak terbatas pada gangguan yang dialami Pengguna, dan fitur yang mengalami eror pada saat Pengguna menggunakan produk dan layanan Pajakku. Data Pribadi ini diperlukan oleh Pajakku sebagai bahan evaluasi untuk terus melakukan perbaikan dari waktu ke waktu untuk mengukur dan meningkatkan kepuasan Pengguna Layanan Aplikasi Pajakku.</p>
                </div>
                <span><strong><span>1.3. Pemrosesan dan Penggunaan Data Pribadi Pengguna</span></strong><span>
                <p>Pajakku menggunakan Data Pribadi Pengguna yang diperoleh dan dikumpulkan dari Pengguna untuk tujuan hal-hal sebagai berikut:</p>
                <div style="margin-left:23px">
                    <p><strong><span>1.3.1.</span></strong> Memproses segala bentuk keperluan yang dibutuhkan Pengguna dalam menggunakan Layanan Aplikasi Pajakku, termasuk namun tidak terbatas pada proses Perpajakan;</p>
                    <p><strong><span>1.3.2.</span></strong> Pajakku hanya akan memproses data yang diberikan oleh Pengguna sesuai dengan ketentuan yang diperbolehkan oleh ketentuan perundang-undangan yang berlaku serta ketentuan <em>Lawfulness</em> <em>of</em> <em>Processing</em> <em>Article</em> <em>6</em> <em>General</em> <em>Data</em> <em>Protection</em> <em>Regulation</em>;</p>
                    <p><strong><span>1.3.3.</span></strong> Pajakku melakukan kegiatan internal yang diperlukan untuk menyediakan layanan seperti pemecahan masalah <em>software, bugs</em>, permasalahan operasional, melakukan analisis data, pengujian, penelitian, dan menganalisis kecenderungan penggunaan dan aktivitas;</p>
                    <p><strong><span>1.3.4.</span></strong> Melakukan pengawasan dan/atau melakukan investigasi terhadap transaksi mencurigakan;</p>
                    <p><strong><span>1.3.5.</span></strong> Menganalisis aktivitas Pengguna, perilaku, dan data demografis termasuk tren dan penggunaan berbagai layanan yang tersedia di Layanan Aplikasi Pajakku;</p>
                    <p><strong><span>1.3.7.</span></strong> Mengirimkan pesan pemasaran secara langsung ataupun yang ditujukkan, iklan, survei, dan informasi sehubungan dengan penawaran promosi;</p>
                    <p><strong><span>1.3.8.</span></strong> Penelitian, analisis, pengembangan dan pengujian produk guna meningkatkan keamanan Layanan Aplikasi Pajakku, serta mengembangkan fitur dan produk baru; dan</p>
                    <p><strong><span>1.3.9.</span></strong> Menggunakan, menyalin, mengirim, menyimpan dan melakukan <em>backup </em>data Pengguna dengan tujuan untuk keperluan Pengguna.</p>
                </div>
            </div>
            <br/>
          <li style="margin-bottom:5px"><strong><span>Pengungkapan Data Pribadi Pengguna</span></strong></li>
            <div>
                <span>Perlindungan Data Pribadi Pengguna adalah hal terpenting bagi Pajakku. Pajakku berkomitmen melakukan upaya terbaik untuk melindungi dan mengamankan Data Pribadi dan Informasi Pribadi Pengguna Kecuali apabila diperlukan dalam hal-hal sebagai berikut:</span>
                <p><strong><span>2.1.</span></strong> Data Pribadi Pengguna yang terdapat dalam akun Pajakku diungkapkan secara terbuka oleh Pajakku dalam rangka memenuhi Layanan Aplikasi Pajakku untuk pemrosesan kegiatan daftar, hitung, bayar, dan lapor, pemberitahuan mengenai pembaharuan kegiatan daftar, hitung, bayar, dan lapor, validasi Informasi Elektronik dan/atau Dokumen Elektronik yang disampaikan Pengguna melalui kegiatan daftar, hitung, bayar, dan lapor yang sudah mutakhir dan akurat, serta pencatatan aktivitas dan mengirimkan pesan (surat elektronik);</p>
                <p><strong><span>2.2.</span></strong> Data Pribadi Pengguna yang diberikan kepada Pihak Ketiga dengan izin Pengguna, merupakan tanggung jawab Pengguna dan Pengguna melepaskan Pajakku dari tanggung jawab terhadap segala penyalahgunaan Data Pribadi Pengguna yang dilakukan oleh Pihak Ketiga;</p>
                <p><strong><span>2.3.</span></strong> Pajakku berhak untuk tidak memberikan Data Pribadi Pengguna kepada Pihak Ketiga (walaupun Pengguna telah memberikan izin) apabila Pajakku meyakini bahwa pemberian Data Pribadi Pengguna tersebut dapat merugikan atau membahayakan Pajakku, melanggar Kebijakan Privasi atau melanggar Hukum Yang Berlaku di Indonesia;</p>
                <p><strong><span>2.4.</span></strong> Dalam keadaan tertentu, Pajakku mungkin perlu untuk menggunakan ataupun mengungkapkan Data Pribadi Pengguna kepada Pihak Ketiga untuk tujuan penegakan hukum atau untuk pemenuhan persyaratan dan/atau kewajiban peraturan perundang-undangan yang berlaku, termasuk dalam hal terjadinya sengketa atau proses hukum antara Pengguna dan Pajakku, atau dugaan tindak pidana seperti penipuan atau pencurian data. Apabila Pajakku membagikan Data Pribadi Pengguna berdasarkan ketentuan Pasal 2.4 dalam Kebijakan Privasi ini, Pihak Ketiga yang menerima Data Pribadi Pengguna dengan ini sepakat dan setuju untuk:</p>
                <div style="margin-left:23px">
                      <p><strong><span>2.4.1.</span></strong> Pengguna hanya dapat melihat Data Pribadi di tempat Pajakku dan dilarang untuk memindahkannya, kecuali apabila dibutuhkan untuk melaksanakan kewajiban kepada Pajakku;</p>
                      <p><strong><span>2.4.2.</span></strong> Menggunakan Data Pribadi yang diungkapkan untuk tujuan dan kepentingan yang sudah ditetapkan; dan</p>
                      <p><strong><span>2.4.4.</span></strong> Mengembalikan Data Pribadi kepada karyawan Pajakku, atau menghancurkannya segera setelah tidak ada kebutuhan lagi.</p>
                </div>
                <p><strong><span>2.5.</span></strong> Seluruh karyawan Pajakku telah menerima sosialisasi tentang Sistem Manajemen Keamanan Informasi berdasarkan ISO 27001:2022 secara berkala; dan</p>
                <p><strong><span>2.6.</span></strong> Dalam hal terjadi perubahan kepemilikan atau perubahan kendali atas semua atau Sebagian dari usaha Pajakku, termasuk <em>Platform</em>, sehubungan dengan dilakukannya penjualan aset perusahaan, penggabungan, pengalihan, konsolidasi atau restrukturisasi, pembiayaan atau akuisisi, usaha patungan, kepailitan, dan/atau tindakan korporasi lainnya, maka Pengguna telah memberikan persetujuan atas Data Pribadi yang akan/dapat menjadi bagian dari pengambilalihan berdasarkan tindakan korporasi tersebut untuk melanjutkan kewajiban pengelolaan Data Pribadi Pengguna sesuai dengan ketentuan dalam Kebijakan Privasi ini.</p>
            </div>
            <br/>
          <li style="margin-bottom:5px"><strong><span>Cookies</span></strong></li>
            <div>
               <p><strong><span>3.1.</span></strong> Cookie adalah berkas data kecil yang ditempatkan <em>browser</em> Pengguna pada komputer atau perangkat seluler Pengguna. Dengan cookie, situs web tersebut dapat "mengingat" tindakan dan preferensi Pengguna yang dapat membantu meningkatkan kenyamanan Pengguna dalam mengakses Platform Pajakku dari waktu ke Namun apabila Pengguna memilih untuk menonaktifkan <em>cookies</em>, maka fungsi Layanan pada <em>browser </em>akan menjadi terbatas.</p>
                <p><strong><span>3.2.</span></strong> Cookies tersebut tidak diperuntukkan untuk digunakan pada saat melakukan akses data lain yang Pengguna miliki di perangkat komputer Pengguna, selain dari yang telah Pengguna setujui untuk</p>
                <p><strong><span>3.3.</span></strong> Walaupun secara otomatis perangkat komputer Pengguna akan menerima cookies, namun Pengguna dapat mengatur browser mereka untuk menolak beberapa jenis cookie tertentu atau cookie spesifik. Di samping itu, Pengguna dapat menghapus cookie kapan saja.</p>
                <p><strong><span>3.4.</span></strong> Pajakku menggunakan <em>Google Analytics Demographics and Interest, Facebook Pixel, </em>atau sejenisnya. Adapun Data yang Pajakku peroleh dari fitur tersebut adalah seperti umur, jenis kelamin, minat Pengguna dan informasi lainnya yang dapat mengidentifikasi Pengguna, akan Pajakku gunakan untuk pengembangan fitur, fasilitas, dan/atau konten yang terdapat pada Situs Pajakku. Jika Pengguna tidak ingin data miliknya terlacak, Pengguna dapat menggunakan <em>Add-On Google Analytics Opt-Out Browser, </em>atau sejenisnya.</p>
                <p><strong><span>3.5.</span></strong> Pajakku dapat menggunakan fitur-fitur yang disediakan oleh Pihak Ketiga dalam rangka meningkatkan layanan dan konten Pajakku, termasuk diantaranya ialah penilaian, penyesuaian, dan penyajian iklan kepada setiap Pengguna berdasarkan minat atau riwayat Jika Pengguna tidak ingin iklan ditampilkan berdasarkan penyesuaian tersebut, maka Pengguna dapat mengaturnya melalui browser.</p>
            </div>
            <br/>
          <li style="margin-bottom:5px"><strong><span>Pembatasan Pemrosesan Data</span></strong></li>
            <div>
                <p><strong><span>4.1.</span></strong> Pajakku memberikan pilihan kepada Pengguna untuk tidak memberikan data Pengguna dan/atau mencabut seluruh persetujuan yang telah diberikan oleh Pengguna kepada Pajakku, dengan mengirimkan permohonan melalui email <a href="mailto:pdp@pajakku.com">pdp@pajakku.com.</a></p>
                <p><strong><span>4.2.</span></strong> Apabila Pengguna memutuskan untuk tidak memberikan datanya dan/atau mencabut seluruh persetujuan sebagaimana dimaksud, maka Pengguna tidak dapat mengakses maupun mendapatkan produk atau layanan Pajakku.</p>
                <p><strong><span>4.3.</span></strong> Persetujuan untuk memberikan atau tidak memberikan atau menarik kembali persetujuan yang sebelumnya telah diberikan oleh Pengguna akan diatur terpisah dalam Syarat dan Ketentuan Layanan Aplikasi Pajakku.</p>
            </div>
          <li style="margin-bottom:5px"><strong><span>Hak Pengguna</span></strong></li>
            <div>
                <span>Pengguna memiliki hak, diantaranya:</span>
                <p><strong><span>5.1.</span></strong> Hak Akses yaitu Hak Pengguna untuk memperoleh konfirmasi dari Pajakku mengenai data Pengguna yang dikumpulkan dan dikelola, dari mana data pribadi itu diambil dan bagaimana data tersebut diproses, sekaligus hak untuk memperoleh salinan data pribadi tersebut;</p>
                <p><strong><span>5.2.</span></strong> Hak Pembetulan yaitu Hak Pengguna untuk memperoleh pembetulan data Pengguna yang tidak akurat atau tidak lengkap tentang diri Pengguna yang ingin melakukan pembetulan dapat melakukan permintaan pembetulan dengan mengirim <em>request </em>kepada Pajakku;</p>
                <p><strong><span>5.3.</span></strong> Hak Penghapusan yaitu Hak Pengguna untuk menghapus Data Pribadi Pengguna dengan merujuk pada ketentuan Pasal 6.2.5 Kebijakan Privasi ini;</p>
                <p><strong><span>5.4.</span></strong> Hak Membatasi Pengolahan Data yaitu Pengguna memiliki hak untuk mengajukan pembatasan pengolahan data dalam keadaan-keadaan tertentu. Pengguna memiliki hak untuk mendapatkan informasi apabila ada perbaikan atau penghapusan data Pengguna; dan</p>
                <p><strong><span>5.5.</span></strong> Hak Portabilitas Data yaitu Pengguna memiliki hak untuk mengunduh data Pengguna yang ada pada</p>
            </div>
            <br/>
          <li style="margin-bottom:5px"><strong><span>Penyimpanan dan Penghapusan Data Penggguna</span></strong></li>
            <div>
                <p><strong><span>6.1.</span></strong> Data Pengguna yang dikumpulkan oleh Pajakku akan disimpan dengan aman sesuai dengan prosedur dan standar sarana pengamanan sesuai dengan yang ditentukan dalam peraturan perundang-undangan berdasarkan Hukum Yang Berlaku di Indonesia. Pajakku akan menyimpan data Pengguna selama diperlukan untuk memenuhi tujuan yang dijelaskan dalam Kebijakan Privasi</p>
                <p><strong><span>6.2.</span></strong> Pajakku melindungi Data Pribadi Pengguna dalam pemrosesan dan penyimpanan yang aman dan tersimpan pada <em>database </em>Pajakku yang terenkripsi, <em>back up </em>data dan arsip pada kontainer terkunci yang tidak mencantumkan label muatan secara spesifik. Dalam hal ini, Pajakku berkomitmen melakukan tindakan dan prosedur keamanan atas seluruh Data Pribadi Pengguna yang diberikan kepada Pajakku dengan syarat</p>
               <div style="margin-left:23px">
                    <p><strong><span>6.2.1.</span></strong> pengumpulan Data Pribadi yang disampaikan sesuai dengan tujuan awal;</p>
                    <p><strong><span>6.2.2.</span></strong> selama masih menjadi Pengguna dari Layanan Aplikasi Pajakku atau untuk kepentingan Pihak Pengandal;</p>
                    <p><strong><span>6.2.3.</span></strong> Landasan atas gugatan hukum yang mungkin timbul;</p>
                    <p><strong><span>6.2.4.</span></strong> Hanya terbatas pada jangka waktu yang diperlukan sesuai dalam ketentuan yang diatur dalam Peraturan Direktur Jenderal Pajak; dan</p>
                    <p><strong><span>6.2.5.</span></strong> masa retensi berlaku sejak tanggal penyerahan Data oleh Pengguna kepada Pajakku sesuai ketentuan dalam Perjanjian Kerja Sama.</p>
                </div>
                <p><strong><span>6.3.</span></strong> Pajakku dapat memblokir akun Pengguna apabila Pengguna terbukti melakukan pelanggaran hukum atas Platform Pajakku yang berdasarkan ketentuan Pajakku, penetapan atau putusan Pengadilan dan/atau sesuai peraturan perundang-undangan. Dalam hal ini, Pajakku akan memberitahukan kepada Pengguna atas pemblokiran akun Pengguna berdasarkan ketentuan ini melalui surat elektronik atau email.</p>
                <p><strong><span>6.4.</span></strong> Pajakku dapat menghapus Data Pribadi Pengguna dalam sistem elektronik Pajakku berdasarkan permintaan tertulis dari Pengguna atau berdasarkan kebijakan Pajakku, dengan proses penghapusan atau pemusnahan Data Pribadi Pengguna apabila Pengguna sudah tidak menggunakan Layanan Aplikasi Pajakku dan/atau permintaan Pengguna dengan dasar penetapan pengadilan untuk penghapusan Data Untuk itu, ketentuan pada proses penghapusan atau pemusnahan Data Pribadi tersebut telah ditentukan oleh Pajakku namun tetap tunduk pada Hukum Yang Berlaku di Indonesia. Terhadap Data pribadi yang telah dihapus, Pajakku tidak dapat menampilkannya kembali.</p>
                <p><strong><span>6.5.</span></strong> Data Pribadi Pengguna masih terdapat kemungkinan disimpan oleh pihak lain, termasuk oleh institusi pemerintah dengan cara tertentu, sebagaimana beberapa Data Pribadi Pengguna yang telah dibagikan kepada Pihak Ketiga ataupun pihak yang bekerja sama dengan Pajakku, dengan ini Pengguna mengakui dan menyetujui untuk penyimpanan Data Pribadi oleh pihak terkait akan mengikuti kebijakan penyimpanan data masing-masing pihak tersebut. Segala informasi yang disampaikan melalui komunikasi antara Pengguna dengan Pihak Ketiga (misalnya Penyelenggara Jasa Sistem Pembayaran, Regulator, Auditor, Advokat, Konsultan, dan/atau Pihak lainnya yang Pajakku tunjuk untuk pelaksanaan operasional usaha Pajakku) yang dilakukan selain pada Platform (seperti melalui SMS, panggilan telepon, WhatsApps, surat elektronik atau email, dan/atau cara komunikasi lainnya guna melakukan pengumpulan Data Pribadi Pengguna oleh Pihak Ketiga) juga penyimpanan yang dilakukan dalam beberapa cara. Pajakku tidak mengizinkan penyimpanan Data Pribadi dengan cara penyimpanan tersebut dan Pajakku tidak bertanggung jawab kepada Pengguna atas penyimpanan Data Pribadi tersebut. Pengguna dengan ini setuju untuk membebaskan, membela, dan memberi ganti rugi atas Pajakku, karyawan, agen, mitra, pemasok, pejabat, direktur, dan afiliasi Pajakku dari dan terhadap setiap dan segala klaim, kewajiban, biaya, kerugian, kerusakan, dan ongkos (termasuk namun tidak terbatas pada pengeluaran biaya ganti rugi penuh dan biaya hukum) yang dihasilkan baik secara langsung maupun tidak langsung atas penyimpanan atas Data Pribadi Pengguna tersebut.</p>
            </div>
            <br/>
          <li style="margin-bottom:5px"><strong><span></span>Perbaikan Data Pribadi Pengguna atau Pencabutan Persetujuan</strong></li>
            <div>
                <p><strong><span>7.1.</span></strong> Pengguna berhak mengakses Data Pribadi Pengguna yang telah tersimpan dalam akun</p>
                <p><strong><span>7.2.</span></strong> Pengguna wajib memberitahu Pajakku segala bentuk perubahan, penambahan, atau pembaharuan informasi pada Data Pengguna dengan disertai dokumen pendukung dan/atau melakukan verifikasi terhadap pembaharuan Data Pribadi Pengguna sesuai dengan prosedur Pajakku.</p>
                <p><strong><span>7.3.</span></strong> Pengguna dapat untuk mengakses atau mengoreksi Data Pengguna yang berada di bawah kendali Pajakku. Namun demikian, permohonan akses tersebut hanya akan diproses oleh Pajakku apabila Pengguna telah menyerahkan bukti identitas yang memadai untuk melakukan akses atau koreksi terhadap data tersebut.</p>
                <p><strong><span>7.4.</span></strong> Pengguna dapat mencabut persetujuan yang berkaitan dengan pemprosesan data Pengguna yang ada di bawah kendali Pajakku dengan mengirimkan permintaan tersebut melalui kontak yang tercantum dalam Kebijakan Privasi ini.</p>
                <p><strong><span>7.5.</span></strong> Pajakku memberikan pilihan kepada Pengguna untuk tidak memberikan data Pengguna dan/atau mencabut seluruh persetujuan yang telah diberikan oleh Pengguna kepada Pajakku.</p>
                <p><strong><span>7.6.</span></strong> Apabila Pengguna memutuskan untuk tidak memberikan datanya dan/atau mencabut seluruh persetujuan sebagaimana dimaksud, maka Pengguna tidak dapat mengakses atau mendapatkan produk atau layanan Aplikasi Pajakku.</p>                       
            </div>
            <br/>
          <li style="margin-bottom:5px"><strong><span></span>Tautan Ke Situs Pihak Ketiga</strong></li>
            <div>
                <p><strong><span>8.1.</span></strong> Layanan Aplikasi pajakku dapat saja berisi tautan ke situs Pihak Ketiga. Pajakku tidak bertanggung jawab atas perolehan, pengumpulan, pengolahan, penganalisisan, penggunaan, penyimpanan, penampilan, pengumuman, pengiriman, penyebarluasan, penghapusan dan pemusnahan Data Pribadi Pengguna oleh Pihak Ketiga Jika Pengguna memberikan informasi langsung ke situs tersebut, kebijakan privasi dan ketentuan layanan di situs tersebut akan berlaku, dan Pajakku tidak bertanggung jawab atas kegiatan pengolahan informasi atau kebijakan privasi dari situs tersebut.</p>
            </div>
            <br/>
          <li style="margin-bottom:5px"><strong><span></span>Platform Pihak Ketiga</strong></li>
            <div>
                <p><strong><span>9.1.</span></strong> Aplikasi, situs web, dan Materi Pemasaran dapat berisi tautan ke situs web yang dioperasikan oleh Pihak Ketiga. Pajakku tidak mengendalikan atau menerima pertanggungjawaban atau tanggung jawab untuk situs web ini dan untuk pengumpulan, penggunaan, pemeliharaan, berbagi, atau pengungkapan data dan informasi oleh Pihak Ketiga tersebut. Silakan baca kebijakan privasi dan syarat dan ketentuan dari situs web Pihak Ketiga tersebut untuk mengetahui bagaimana mereka mengumpulkan dan menggunakan Informasi Pribadi Pengguna.</p>
                <p><strong><span>9.2.</span></strong> Saat Pengguna menggunakan Layanan Aplikasi Pajakku di perangkat seluler Pengguna untuk keperluan autentikasi, harap dicatat bahwa Pajakku tidak menyimpan data biometrik tersebut. Kecuali diberitahukan lain kepada Pengguna, data tersebut tersimpan di perangkat seluler Pengguna dan dapat disimpan oleh Pihak Ketiga, seperti pembuat perangkat Pengguna setuju dan mengakui bahwa Pajakku tidak bertanggung jawab atas setiap akses tidak sah atau kerugian apapun atas data biometrik yang tersimpan di perangkat seluler Pengguna.</p>
                <p><strong><span>9.3.</span></strong> Segala kerugian akibat Data Pribadi yang dicegat, disadap, diakses, dicuri, diungkapkan, diubah atau dihancurkan oleh Pihak Ketiga yang tidak berwenang, karena faktor-faktor di luar kendali Pajakku dan/atau yang bertentangan dengan Kebijakan Privasi ini dan peraturan perundang- undangan yang berlaku, termasuk yang diakibatkan oleh kesalahan atau kelalaian Pemilik.</p>
                <p><strong><span>9.4.</span></strong> Iklan yang terdapat pada Layanan Aplikasi Pajakku, situs web, dan Materi Pemasaran Pajakku berfungsi sebagai tautan ke situs web pengiklan dan dengan demikian segala informasi yang mereka kumpulkan berdasarkan klik Pengguna pada tautan itu akan dikumpulkan dan digunakan oleh pengiklan yang relevan sesuai dengan kebijakan privasi pengiklan tersebut.</p>
            </div>
            <br/>
          <li style="margin-bottom:5px"><strong><span></span>Pengaduan terkait Perlindungan Data Pengguna</strong></li>
            <div>
                <p><strong><span>10.1.</span></strong> Jika Pengguna memiliki kekhawatiran tentang penanganan atau perlakuan Pajakku terhadap Data Pribadi Pengguna atau jika Pengguna yakin bahwa privasinya telah dilanggar, Pengguna dapat menghubungi Pajakku melalui layanan pelanggan Pajakku atau kepada kontak Pajakku yang tercantum dalam Kebijakan Privasi ini dengan menjelaskan identitas dan sifat keluhan</p>
                <p><strong><span>10.2.</span></strong> Pajakku akan menyelidiki keluhan Pengguna dan berupaya untuk memberikan tanggapan terhadap keluhan tersebut dalam kurun waktu yang wajar setelah menerima keluhan yang disampaikan oleh</p>
            </div>
            <br/>
          <li style="margin-bottom:5px"><strong><span></span>Hubungi Pajakku</strong></li>
            <div>
                <p>Jika Pengguna memiliki pertanyaan atau komentar mengenai Kebijakan Privasi ini atau ingin mendapatkan akses atau koreksi terhadap Data Pribadi Pengguna, Dalam hal terjadi kegagalan perlindungan Data Pribadi, Pajakku wajib menyampaikan pemberitahuan tertulis paling lambat 3x24 (Tiga Kali Dua Puluh Empat) jam kepada Pengguna dan lembaga terkait, Pajakku silahkan hubungi Pajakku melalui <a href="mailto:pdp@pajakku.com">pdp@pajakku.com.</a></p>
            </div>
            <br/>
          <li style="margin-bottom:5px"><strong><span></span>Pembaruan Kebijakan Privasi</strong></li>
            <div>
                <p><strong><span>12.1.</span></strong> Pajakku dapat setiap saat mengubah, memperbarui, dan/atau menambahkan sebagian ataupun seluruh ketentuan dalam Kebijakan Privasi ini, sesuai dengan bisnis Pajakku ke depan, dan/atau perubahan peraturan perundang-undangan yang berlaku. Dengan tetap mengakses dan menggunakan layanan Situs maupun Layanan Aplikasi Pajakku, maka Pengguna dianggap menyetujui perubahan-perubahan dalam Kebijakan Privasi.</p>
            </div>
            <br/>
            <p>Diperbaharui pada tanggal : 22 Juli 2024</p>

        </ol>
    </div>
</span>
`

export default Privacy
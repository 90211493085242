import React, { useState, useEffect } from 'react'
import t from 'counterpart'
import './DataFilter.scss'
import Label from '../Label'
import { Button, TextField, FontIcon, Select } from 'react-md'
import { defaultQueryParameter, strCriterias } from '../../config/constant'
import CustomTextField from '../CustomTextField'
import CustomInput from '../CustomInput'
import moment from 'moment'
import { find } from 'lodash'
import DataForm from '../DataForm'

const { inputTypes } = DataForm

const DataFilter = ({
  baseId = 'mpk-data-filter',
  columns = [],
  defaultQuery = {},
  onSubmit = () => console.log(`[react-mpk][component][data-filter] The onSubmit props has not been defined yet`),
  showPeriod = true,
  useCriteria = true,
  extraFilter = null,
  children = null,
  multiColumn = false
}) => {
  const [query, setQuery] = useState(defaultQueryParameter(defaultQuery))
  const [currentOptions, setCurrentOptions] = useState(null)
  const [currentDefinition, setCurrentDefinition] = useState(null)
  const [definitions, setDefinitions] = useState([])
  const [currentCriterias, setCurrentCriterias] = useState(null)

  const handleSubmit = e => {
    if (e) e.preventDefault()
    if (e) e.stopPropagation()
    onSubmit(query)
  }

  const handleChange = (key, value) => {
    if (!multiColumn) {
      if (key === 'endDate' && moment(value).isBefore(moment(query.startDate))) value = query.startDate
      if (key === 'startDate' && moment(value).isAfter(moment(query.endDate))) value = query.endDate

      if (key === 'column') handleSetCurrentOptions(value)
      else setQuery(query)

    } else {
      setQuery(query)
    }
  }

  useEffect(() => {
    sessionStorage.removeItem('saved-filter')
  }, [])

  const handleClear = () => {
    sessionStorage.removeItem('saved-filter')
    if (multiColumn) {
      handleSetMultiColumn(true)
    }
    else {
      let obj = { keyword: '', startDate: '', endDate: '', }
      setQuery(q => ({ ...q, ...obj }))
      onSubmit(obj)
    }
  }

  const handleSetCurrentOptions = (column, defaultQueryDidChange) => {
    let item = find(columns, { value: column })
    if (item) {
      setCurrentOptions(item ? item.options : null)
      setCurrentDefinition(item.definition ? {
        label: t.translate('mpk.column.keyword'),
        ...item.definition,
        key: 'keyword',
      } : null)
      if (item && item.options && item.options.length > 0) {
        handleChange('keyword', item.options[0].value)
      }
      if (item && item.definition) {
        let { criterias } = item.definition
        if (criterias && Array.isArray(criterias)) {
          setCurrentCriterias(criterias)
          setQuery(q => {
            q = defaultQueryDidChange ? { ...q, ...defaultQuery } : { ...q }
            q.columnCriteria = criterias[0]
            q.column = column
            return { ...q }
          })
        }
      } else {
        setCurrentCriterias(null)
      }
    }
  }

  const handleSetMultiColumn = (isClear = false) => {
    let objQuery = {}
    if (useCriteria) {
      for (let c of columns) {
        let { label, value, definition = {}, ...col } = c
        let key = definition.key || col.key || value
        let { criterias } = definition
        let inputType = definition.inputType || (
          col.options || definition.options ? inputTypes.SELECT : inputTypes.INPUT
        )
        let asTextField = [
          inputTypes.INPUT,
          inputTypes.INPUT_MASK,
          inputTypes.INPUT_MASK_NUMBER,
          inputTypes.TEXTAREA,
          inputTypes.DATE,
          inputTypes.DATEPICKER,
          inputTypes.DATETIME
        ].indexOf(inputType) >= 0
        if (isClear) {
          objQuery[key] = ''
          if (asTextField) objQuery[`${key}.criteria`] = Array.isArray(criterias) && (criterias[0] || 'contains')
          onSubmit(objQuery)
        } else {
          objQuery[key] = definition.defaultValue || defaultQuery[key] || ''
          if (asTextField) objQuery[`${key}.criteria`] = defaultQuery[`${key}.criteria`] || (Array.isArray(criterias) && (criterias[0] || 'contains'))
        }
      }
    }
    setQuery(objQuery)
  }

  const handleSetDefinitions = () => {
    let __definitions = []
    for (let c of columns) {
      let { label, value, definition = {}, ...col } = c
      let key = definition.key || col.key || value
      let { criterias } = definition
      let inputType = definition.inputType || (
        col.options || definition.options ? inputTypes.SELECT : inputTypes.INPUT
      )
      let asTextField = [
        inputTypes.INPUT,
        inputTypes.INPUT_MASK,
        inputTypes.INPUT_MASK_NUMBER,
        inputTypes.TEXTAREA,
        inputTypes.DATE,
        inputTypes.DATEPICKER,
        inputTypes.DATETIME
      ].indexOf(inputType) >= 0

      if (useCriteria && asTextField) {
        if (c.multiInputSearch) {
          __definitions.push({
            render: <Label>
              <div className="mpk-font size-S">{label}</div>
            </Label>
          })

          if(c.definition && c.definition.criterias && c.definition.criterias.length > 0){
            c.definition.criterias.map((z)=> {
              __definitions.push({
                inputType: inputTypes.SELECT,
                label: `${t.translate('mpk.column.criteria')} ${definition.label || label}`,
                key: `${key}_${z.value}.criteria`,
                value: z.value,
                style: { display: 'none' },
                options: (criterias || strCriterias).map(d => ({
                  label: z.label,
                  value: z.value
                }))
              })
              __definitions.push({
                inputType,
                key: `${key}_${z.value}`,
                label: z.label || definition.label || label,
                clearable: true,
                ...definition,
              })
            })
          }


        } else {
          __definitions.push({
            render: <Label>
              <div className="mpk-font size-S">{label}</div>
            </Label>
          })

          __definitions.push({
            inputType: inputTypes.SELECT,
            label: `${t.translate('mpk.column.criteria')} ${definition.label || label}`,
            key: `${key}.criteria`,
            options: (criterias || strCriterias).map(d => ({
              label: t.translate(`mpk.column.${d}`),
              value: d
            }))
          })

          __definitions.push({
            inputType,
            key,
            label: definition.label || label,
            ...definition
          })
        }
      }

    }
    setDefinitions(__definitions)
  }

  useEffect(async () => {
    if (multiColumn) {
      handleSetMultiColumn()
      handleSetDefinitions()
    } else {
      if (defaultQuery.column && defaultQuery.column !== query.column) handleSetCurrentOptions(defaultQuery.column, true)
      else setQuery(q => ({ ...q, ...defaultQuery }))
    }
  }, [defaultQuery])

  const [formValues, setFormValues] = useState({})
  useEffect(() => {
    setTimeout(()=> {
      setQuery({
        ...query,
        ...formValues
      })
    }, 50)
  }, [formValues])

  return (
    <DataForm
      baseId={baseId}
      defaultData={query}
      definitions={[
        {
          render: <Label>{t.translate('mpk.sentence.dataFilter')}</Label>
        },
        ...multiColumn ? [
          ...definitions,
        ] : [
          {
            inputType: inputTypes.SELECT,
            label: t.translate('mpk.column.searchColumn'),
            key: 'column',
            options: columns
          },
          ...useCriteria ? [
            {
              inputType: inputTypes.SELECT,
              label: t.translate('mpk.column.criteria'),
              key: 'columnCriteria',
              options: currentCriterias || strCriterias
            }
          ] : [],
          ...[currentOptions
            ? {
              inputType: inputTypes.SELECT,
              label: t.translate('mpk.column.keyword'),
              key: 'keyword',
              options: currentOptions
            } :
            currentDefinition ? currentDefinition : {
              inputType: inputTypes.INPUT,
              label: t.translate('mpk.column.keyword'),
              key: 'keyword',
              rightChildren: (
                <FontIcon iconClassName="mdi mdi-magnify" />
              )
            }
          ],
        ],
        // {
        //   inputType:inputTypes.PRE_DATA
        // },
        ...extraFilter ? [{
          render: (
            extraFilter && (
              <div className="mpk-margin-N margin-top margin-bottom mpk-full full-width">
                {extraFilter}
              </div>
            )
          )
        }] : []
      ]}
      onChange={(data, key, value) => {
        handleChange(key, value)
        sessionStorage.setItem('saved-filter', JSON.stringify(data))
        setFormValues(data)
        return data
      }}
      onSubmit={(newQuery, callback) => {
        sessionStorage.setItem('remove-filter', false)
        sessionStorage.setItem('saved-filter', JSON.stringify(newQuery))
        setFormValues(newQuery)
        onSubmit(newQuery)
        callback()
      }}
      additionalAction={[
        {
          render: () => (
            <Button
              themeType="outline"
              className="flex"
              onClick={()=> {
                sessionStorage.setItem('remove-filter', true)
                handleClear()
              }}
            >
              Clear
            </Button>
          )
        }
      ]}
    />
  )

  // return multiColumn ? (
  //   <DataForm
  //     baseId={baseId}
  //     defaultData={query}
  //     definitions={[
  //       {
  //         render: <Label>{t.translate('mpk.sentence.dataFilter')}</Label>
  //       },
  //       ...definitions,
  //       ...extraFilter ? [{
  //         render: (
  //           extraFilter && (
  //             <div className="mpk-margin-N margin-top margin-bottom">
  //               {extraFilter}
  //             </div>
  //           )
  //         )
  //       }] : []
  //     ]}
  //     onSubmit={(newQuery, callback) => {
  //       onSubmit(newQuery)
  //       callback()
  //     }}
  //     additionalAction={[
  //       {
  //         render:() => (
  //           <Button 
  //             themeType="outline"
  //             className="flex"
  //             onClick={handleClear}
  //           >
  //             Clear
  //           </Button>
  //         )
  //       }
  //     ]}
  //   />
  // ) : (
  //   <form 
  //     className="mpk-data-filter mpk-paper mpk-padding-N padding-all"
  //     onSubmit={handleSubmit}
  //   >
  //     <Label>{t.translate('mpk.sentence.dataFilter')}</Label>
  //     <Select
  //       id={`${baseId}-column-options`}
  //       label={t.translate('mpk.column.searchColumn')}
  //       className="mpk-margin-N margin-bottom"
  //       value={query.column}
  //       onChange={ value => handleChange('column', value)}
  //       options={columns}
  //     />
  //     {useCriteria && (
  //       <Select
  //         id={`${baseId}-column-criteria`}
  //         label={t.translate('mpk.column.criteria')}
  //         className="mpk-margin-N margin-bottom"
  //         value={query.columnCriteria}
  //         onChange={value => handleChange('columnCriteria', value)}
  //         options={strCriterias.map(d => ({
  //           label: t.translate(`mpk.column.${d}`),
  //           value: d
  //         }))}
  //       />
  //     )}
  //     {currentOptions && currentOptions.length > 0 ? (
  //       <Select
  //         id={`${baseId}-keyword-column`}
  //         label={t.translate('mpk.column.keyword')}
  //         className="mpk-margin-N margin-bottom"
  //         placeholder={t.translate('mpk.sentence.inputKeyword')}
  //         value={query.keyword}
  //         onChange={value => handleChange('keyword', value)}
  //         options={currentOptions}
  //       />
  //     ) : (
  //       <CustomInput
  //         id={`${baseId}-keyword`}
  //         label={t.translate('mpk.column.keyword')}
  //         className="mpk-margin-N margin-bottom"
  //         placeholder={t.translate('mpk.sentence.inputKeyword')}
  //         value={query.keyword}
  //         onChange={(e) => handleChange('keyword', e.target.value)}
  //         rightChildren={
  //           <FontIcon iconClassName="mdi mdi-magnify"/>
  //         }
  //         helpText={useCriteria && query.columnCriteria === 'in' && t.translate('mpk.sentence.criteriaInHelp')}
  //         dense
  //       />
  //     )}
  //     {showPeriod && (
  //       <>
  //         <Label>{t.translate('mpk.column.period')}</Label>{}
  //         <TextField
  //           id={`${baseId}-start-date`}
  //           className="mpk-margin-N margin-bottom"
  //           label={t.translate('mpk.column.startDate')}
  //           onChange={(e) => handleChange('startDate', e.target.value)}
  //           value={query.startDate}
  //           type="date"
  //         />
  //         <TextField
  //           id={`${baseId}-end-date`}
  //           className="mpk-margin-N margin-bottom"
  //           label={t.translate('mpk.column.endDate')}
  //           onChange={(e) => handleChange('endDate', e.target.value)}
  //           value={query.endDate}
  //           type="date"
  //         />
  //       </>
  //     )}
  //     {extraFilter && (
  //       <div className="mpk-margin-N margin-top margin-bottom">
  //         {extraFilter}
  //       </div>
  //     )}
  //     <div className="actions mpk-flex">
  //       <Button     
  //         themeType="outline"
  //         className="flex"
  //         className="mpk-margin-S margin-right flex"
  //         onClick={handleClear}
  //       >
  //         Clear
  //       </Button>
  //       <Button
  //         theme="primary"
  //         themeType="contained"
  //         className="flex"
  //         type="submit"
  //       >
  //         {t.translate('mpk.column.submit')}
  //       </Button>
  //     </div>
  //     {children && (
  //       <div className="mpk-margin-N margin-bottom">
  //         {children}
  //       </div>
  //     )}
  //   </form>
  // )
}

export default DataFilter

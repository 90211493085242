import React, {useState, useEffect} from 'react'
import { Router, Switch, Route } from "react-router-dom"
import { PageWrapper, TableWrapper } from '../../libs/react-mpk/wrapper'
import { sso } from '../../libs/react-mpk/hocs'
import {FormSample, BuktiPotong, TableLogImport, TableLogScan } from '../../modules';
import { toast } from '../../libs/react-mpk/services';
import { Notification } from '../../libs/react-mpk/components';
import notificationService, {updateNotificationBulk} from '../../modules/Notification/Notification.service'
import notificationServiceSupport, {updateNotificationBulkSupport} from '../../modules/Notification/NotificationSupport.service'
import {downloadReport} from '../../modules/TableBuktiPotong/TableBuktiPotong.service'
import {downloadReportSupport} from '../../modules/TableBuktiPotong/TableBuktiPotongSupport.service'
import { useParams } from 'react-router'
import moment from 'moment'

import { inject, observer } from "mobx-react";
import qs from 'query-string'



const Company = ({history, authStore,envStore}) => {
  let isSupport = authStore.user.isSupport
  let [mStompClient, setMStompClient] = useState(null)
	let [nStompClient, setNStompClient] = useState(null)
	let [notifCount, setNotifCount] = useState(0)
  let{ companyId } = useParams()
	const initWs = () => {
		let accessToken = authStore.getAccessToken()
		let accessKey = authStore.getAccessKey()
		let { host, baseUrl } = envStore.env['apiGateway']
		let params = {
      'companyId': companyId
    }
		if (accessKey) params.access_key = accessKey;
		else if (accessToken) params.access_token = accessToken
    let nSocket = window.SockJS(`${host}${baseUrl}/scan_bunifikasi/ws?${qs.stringify({channel: 'notification', ...params})}`)


		nStompClient = window.Stomp.over(nSocket)
		nStompClient.connect({}, onNWsConnected, onNWsError)
	}


	const onNWsConnected = () => {
		nStompClient.subscribe('/users/queue/notification', onNMessageReceived);
		setNStompClient(nStompClient)
	}

	const onNWsError = (error) => {
		console.log('WS Notification Error')
		console.log(error)
		toast.error(error)
		setTimeout(() => {
			console.log('WS Notification Reconnecting...')
  		nStompClient.connect({}, onNWsConnected, onNWsError)
			setNStompClient(nStompClient)
		}, 1000)
	}

	const onNMessageReceived = (payload) => {
		let message = JSON.parse(payload.body)
		setNotifCount(count => (count+1))
	}



  useEffect(() => {
		initWs()
	}, [])

  return (
    <PageWrapper
      appbar={
        {
          subname: isSupport ?'- Support':'',
          rightCorner: (
            <Notification
              count={notifCount}
              onOpen={async (data) => {
                try {
                  if(isSupport){
                    await updateNotificationBulkSupport({'isRead.equals': false})
                  }else{
                    await updateNotificationBulk({'isRead.equals': false})
                  } 
                  setNotifCount(0)
                } catch (error) {
                  toast.errorRequest(error)
                  console.log("🚀 ~ file: Company.routes.js ~ line 86 ~ onOpen={ ~ error", error)
                }
                // for(let item of data){
                //   if(!item.isRead){
                //     if(isSupport){
                //       await notificationServiceSupport.put(item.id, {...item, isRead: true})
                //     }else{
                //       await notificationService.put(item.id, {...item, isRead: true})
                //     }
                //   }
                // }
              }}
              onFetchData={(query) => {
                return new Promise(async (resolve, reject) => {
                  try {
                    let res;
                    if(isSupport){
                      res = await notificationServiceSupport.get({...query, sort:'createdDate,DESC'})
                    }else{
                      res = await notificationService.get({...query, sort:'createdDate,DESC'})
                    }
                    let unread = res.data.filter(d => !d.isRead).length
                    setNotifCount(unread)
                    resolve(res)
                  } catch (error) {
                    reject(error)
                  }
                })
              }}
              render={item => (
                <>
                  {item.notification.toLowerCase() === 'export' ? (
                    <div 
                      className="mpk-link"
                      onClick={async () => {
                        try {
                          if(isSupport){
                            await downloadReportSupport(item.data)
                          }else{
                            await downloadReport(item.data)	
                          }
                        } catch (error) {
                          console.log("🚀 ~ file: Company.routes.js ~ line 121 ~ onClick={ ~ error", error)
                          toast.errorRequest(error)
                        }
                      }}
                    >
                      {item.data}
                    </div>
                  ) : (
                    <div>{item.data}</div>
                  )}
                  <div className="mpk-font size-S color-D3">{moment(item.createdDate).format('lll')}</div>
                </>
              )}
            />
          )
        }
      }>
      <Router history={history}>
        <Switch>
          <Route
            path='/product/company/:companyId/details/:tab'
            render={props => (
              <BuktiPotong {...props}/>
            )}
          />
          <Route
            path='/product/company/:companyId/logExport'
            render={props => (
              <TableLogImport {...props}/>
            )}
          />
          <Route
            path='/product/company/:companyId/logScan'
            render={props => (
              <TableLogScan {...props}/>
            )}
          />
        </Switch>
      </Router>
    </PageWrapper>
  )
}

export default sso({
  basePath: '/product/company/:companyId',
  url:{
    me:'/api/sso/company/:companyId/me'
  }
})(inject("authStore","envStore")(Company))
